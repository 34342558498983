import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import Swal from 'sweetalert2'
import { AppToastServiceService } from '../services/app-toast-service.service';
import { EncrDecrServiceService } from '../services/encr-decr-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user = '1';
  getUserData_A: any = [];
  email: any;
  password: any;
  showToast: any = 0;
  encrypted:any;

  all_user_email:any=[];
  email_exist_or_not:boolean;
  
  constructor(private EncrDecr: EncrDecrServiceService,private mysql: MysqlserviceService, private fb: FormBuilder, private route: Router, private toastService: AppToastServiceService) { }

  ngOnInit(): void {
    
    this.mysql.getUsers().subscribe((res: any) => {

      res.forEach(elements => {
        this.getUserData_A.push(elements);
        this.email = elements.email;
        this.password = elements.password;

         //make array of all user emails only
        this.all_user_email.push(elements.email)
      })
      // console.log(this.getUserData_A)
      // console.log("emil--------------------",this.email)

      // console.log("user name", this.getUserData_A[0].f_name);

      


    },e =>{
      Swal.fire({
        title: 'Error',
        text: 'Server Error, Try again later',
        icon: 'error',
        confirmButtonText: 'cancel',
        width: '400'
      })
    })
  }

  regiLogin = this.fb.group({
    email: ['', [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
    password: ['', [Validators.required, Validators.minLength(6)]]
  });

  public errMsg = {
    email: [
      { type: 'required', message: 'This field cannot be blank' },
      { type: 'pattern', message: 'Please enter a valid email address' }
    ],
    password: [
      { type: 'required', message: 'This field cannot be blank' },
      { type: 'minlength', message: 'Minimum 6 digit password required' }

    ],
  }

//   showToasterSuccess(){
//     this.toastService.showSuccess("Data shown successfully !!", "ItSolutionStuff.com")
// }

// showToasterError(){
//     this.toastService.showError("Something is wrong", "ItSolutionStuff.com")
// }

// showToasterInfo(){
//     this.toastService.showInfo("This is info", "ItSolutionStuff.com")
// }

// showToasterWarning(){
//     this.toastService.showWarning("This is warning", "ItSolutionStuff.com")
// }




login(){
  var flag1 = 0
  this.showToast = 1;
   
    
    // console.log("emails in login-",this.regiLogin.value.email+'\n'+this.regiLogin.value.password)

    //check email
    
    if(this.all_user_email.includes(this.regiLogin.value.email))             //-----------------------------------if-1----check email
    {
      this.mysql.get_encrypted_password(this.regiLogin.value.email).subscribe((response:any)=>{
        // console.log(JSON.stringify(response))
        response.forEach(a => {
          this.encrypted=a[0].password
        })
           
        // console.log("encrypted value : ",this.encrypted)
          var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', this.encrypted);
          // console.log("decrypted value : ",decrypted)
          // console.log("element.email",element.email)

        if ( this.regiLogin.value.password == decrypted) {    //---------------------------------if-2----check password
          // console.log("in if condition")
          flag1 = 1
          // console.log("flag",flag1)
          let arr = [{ email:this.regiLogin.value.email }]
          this.mysql.getUserId(arr).subscribe(a => {
  
            if (a[0].aadharcard_status == 0 && a[0].pancard_status == 0) {
  
              localStorage.setItem('email', this.regiLogin.value.email);
              this.route.navigateByUrl('/signup');
  
            }
            else if (a[0].aadharcard_status == 1 && a[0].pancard_status == 0) {
  
              localStorage.setItem('email', this.regiLogin.value.email);
              this.route.navigateByUrl('/signup');
  
            }
            else if (a[0].aadharcard_status == 1 && a[0].pancard_status == 1) {
              localStorage.setItem('SeesionUser', a[0].user_id)
              this.toastService.showSuccess("Logged in successfully")
              this.route.navigateByUrl('/view-table');
            }
            else {
              this.route.navigateByUrl('/view-table');
            }
          },
           (e) => {
             //---getUserId error code---
            Swal.fire({
              title: 'Error',
              text: 'Server Error, Try again later',
              icon: 'error',
              confirmButtonText: 'cancel',
              width: '400'
            })
          },
           () => {
              //---getUserId compelete code---

          })  //--getUserId subscribe
        }                                      //---------------------------------closing of if-2----check password
        else  
        {
          Swal.fire({
                  title: 'Error',
                  text: 'Incorrect Password..! Please try again.',
                  icon: 'warning',
                  confirmButtonText: 'Ok',
                  width: '400'
                })
        }                                             

      })  //----------get_encrypted_password subscribe
 
    }
    else                                                          //-----------------------else-1  //if email inavlid
    {
      Swal.fire({
        title: 'Error',
        text: 'Email not registered..!! Please signup first.',
        icon: 'warning',
        confirmButtonText: 'Ok',
        width: '400'
      })
    }
    
} //end of login
}