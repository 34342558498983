import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as redstone from 'redstone-api';
import { AppToastServiceService } from '../services/app-toast-service.service';

@Component({
  selector: 'app-view-offer-table',
  templateUrl: './view-offer-table.component.html',
  styleUrls: ['./view-offer-table.component.css']
})
export class ViewOfferTableComponent implements OnInit, AfterViewInit {
  @ViewChild('valueId') usernameElement: ElementRef;
  @ViewChild('selectid') optselElement1: ElementRef;

  firstselect: any;
  secondselect: any;

  defaultVal = 1;
  defaultVal2;

  allVal: any = []

  getCoinData_A: any = [];
  getCoinData_B: any = [];
  mlistB: any = [];

  binanceCalVal: any;
  ChangeNowValue: any;
  simpleSwapCal: any;
  exolicCalVal: any;
  stealthExValue: any;
  godexCalVal: any;
  changellyCalVal: any;
  switchainCalVal: any;
  letsexchangeCalVal: any;
  NexchangeCalVal: any;
  changeheroCalVal: any;
  foxexchangeCalVal: any;

  cotoexcalval = 0;
  binanceCalVal1 = 0;
  ChangeNowValue1 = 0;
  simpleSwapCal1 = 0;
  exolicCalVal1 = 0;
  stealthExValue1 = 0;
  godexCalVal1 = 0;
  changellyCalVal1 = 0;
  switchainCalVal1 = 0;
  letsexchangeCalVal1 = 0;
  NexchangeCalVal1 = 0;
  changeheroCalVal1 = 0;
  foxexchangeCalVal1 = 0;

  cotoexDiv = 0;

  toLower1: any;
  toLower2: any;

  CotoexEnDis: any = 1;
  BinanceEnDis: any = 1;
  DisabledExolix: any = 1;
  ChangeNowEnDis: any = 1;
  SimSwapEnDis: any = 1;
  StealthEXEnDis: any = 1;
  GodexEnDis: any = 1;
  ChangellyEnDis: any = 1;
  swithainEnDis: any = 1;
  LetsExchangeEnDis: any = 1;
  NExchngerEnDis: any = 1;
  changeheroEnDis: any = 1;
  foxexchangeEnDis: any = 1;

  cotoexCss: any;
  binanceCss: any;
  exolixCss: any;
  changeNowCss: any;
  simpleSwapCss: any;
  stealthEXCss: any;
  godexCss: any;
  changellyCss: any;
  switchainCss: any;
  letsExchangeCss: any;
  NexchangeCss: any;
  changeHeroCss: any;
  foxexchangeCss: any;

  closeResult: any = '';

  mlist: any = [];
  name: string;
  name2: string;

  inputCalVal: any;
  bigb_bnb: any;
  usdConversion: any;

  constructor(private http: HttpClient,private toastService: AppToastServiceService, private mysql: MysqlserviceService, private fb: FormBuilder, private route: Router, private modalService: NgbModal) {
    setInterval(() =>
      this.secondDropdwnVal()
      , 1000);
  }
  ngAfterViewInit() {
    this.cotoexCss = document.getElementById('cotoexDefault1_id');
    this.exolixCss = document.getElementById('exolicDefault1_id');
    this.changeNowCss = document.getElementById('changenowDefault1_id');
    this.simpleSwapCss = document.getElementById('simpleSwapDefault1_id');
    this.stealthEXCss = document.getElementById('stealthEXDefault1_id');
    this.godexCss = document.getElementById('godexDefault1_id');
    this.changellyCss = document.getElementById('changellyDefault1_id');
    this.switchainCss = document.getElementById('switchainDefault1_id');
    this.letsExchangeCss = document.getElementById('letsExchangeDefault1_id');
    this.NexchangeCss = document.getElementById('NExchangeDefault1_id');
    this.binanceCss = document.getElementById('binanceDefault1_id');
    this.changeHeroCss = document.getElementById('changeheroDefault1_id');
    this.foxexchangeCss = document.getElementById('foxexchangeDefault1_id');


    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()


    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=godex&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((ab: any) => {
      if (ab[0] == ' ' || ab[0] == undefined || ab[0] == null) {
        this.GodexEnDis = 0;
        this.godexCss.style.color = 'red';
        this.godexCss.style.fontSize = 'large';
        this.godexCalVal = 'Not Available'
      }else{
      if (this.usernameElement.nativeElement.value < ab[0].min) {
        // console.log("\n----")
        this.GodexEnDis = 0;
        this.godexCss.style.color = 'red'
        this.godexCalVal = 'Min : ' + ab[0].min;
      }
      else {
        if (ab[0].amount == 0) {
          this.GodexEnDis = 0;
          this.godexCss.style.color = 'red';
          this.godexCss.style.fontSize = 'large';
          this.godexCalVal = 'Not Available'
        }
        else {
          this.godexCalVal = (ab[0].amount).toFixed(8);
          // console.log("\n-------")
          this.GodexEnDis = 1;
          this.godexCss.style.color = '#00c162'
          this.godexCalVal = (ab[0].amount).toFixed(8);
          this.godexCalVal1 = (ab[0].amount).toFixed(8);
        }
      }
    }
    }, (e) => {
      this.GodexEnDis = 0;
      this.godexCss.style.color = 'red';
      this.godexCss.style.fontSize = 'large';
      this.godexCalVal = 'Not Available'
    }, () => {

    })

    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=simpleswap&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((sswap: any) => {
      if (sswap[0] == ' ' || sswap[0] == undefined || sswap[0] == null) {
        this.SimSwapEnDis = 0;
        this.simpleSwapCss.style.color = 'red';
        this.simpleSwapCss.style.fontSize = 'large';
        this.simpleSwapCal = 'Not Available'
      }
      else{
      if (this.usernameElement.nativeElement.value < sswap[0].min) {
        // console.log("\n----")
        this.SimSwapEnDis = 0;
        this.simpleSwapCss.style.color = 'red'
        this.simpleSwapCal = 'Min : ' + sswap[0].min;
      }
      else {
        if (sswap[0].amount == 0) {
          this.SimSwapEnDis = 0;
          this.simpleSwapCss.style.color = 'red';
          this.simpleSwapCss.style.fontSize = 'large';
          this.simpleSwapCal = 'Not Available'
        }
        else {
          this.simpleSwapCal = sswap[0].amount.toFixed(8)
          // console.log("\n-------")
          this.SimSwapEnDis = 1;
          this.simpleSwapCss.style.color = '#00c162'
          this.simpleSwapCal = sswap[0].amount.toFixed(8)
          this.simpleSwapCal1 = sswap[0].amount.toFixed(8)
        }
      }
    }
    }, (e) => {
      this.SimSwapEnDis = 0;
      this.simpleSwapCss.style.color = 'red';
      this.simpleSwapCss.style.fontSize = 'large';
      this.simpleSwapCal = 'Not Available'
    }, () => {

    })


    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=stealthex&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((stealthex: any) => {
      if (stealthex[0] == ' ' || stealthex[0] == undefined || stealthex[0] == null) {
        this.StealthEXEnDis = 0
        this.stealthEXCss.style.color = 'red'
        this.stealthExValue = 'Not Available'
        this.stealthEXCss.style.fontSize = 'large'
      }else{
      if (this.usernameElement.nativeElement.value < stealthex[0].min) {
        // console.log("\n----")
        this.StealthEXEnDis = 0;
        this.stealthEXCss.style.color = 'red'
        this.stealthExValue = 'Min : ' + stealthex[0].min;
      }
      else {
        if (stealthex[0].amount == 0) {
          this.StealthEXEnDis = 0
          this.stealthEXCss.style.color = 'red'
          this.stealthExValue = 'Not Available'
          this.stealthEXCss.style.fontSize = 'large'
        }
        else {
          this.stealthExValue = (stealthex[0].amount).toFixed(8)
          // console.log("\n-------")
          this.StealthEXEnDis = 1;
          this.stealthEXCss.style.color = '#00c162'
          this.stealthExValue = (stealthex[0].amount).toFixed(8)
          this.stealthExValue1 = (stealthex[0].amount).toFixed(8)
        }
      }
    }
    }, (e) => {
      this.StealthEXEnDis = 0;
      this.stealthEXCss.style.color = 'red'
      this.stealthExValue = 'Not Available'
      this.stealthEXCss.style.fontSize = 'large'
    }, () => {

    })
    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=changenow&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((cn: any) => {
      // console.log('changenow min value-----' + JSON.stringify(cn))

      if (cn[0] == ' ' || cn[0] == undefined || cn[0] == null) {
        this.ChangeNowEnDis = 0;
        this.changeNowCss.style.color = 'red'
        this.changeNowCss.style.fontSize = 'large'
        this.ChangeNowValue = 'Not Available'
      }else{

      if (this.usernameElement.nativeElement.value < cn[0].min) {
        // console.log("\n----")
        this.ChangeNowEnDis = 0;
        this.changeNowCss.style.color = 'red'
        this.ChangeNowValue = 'Min : ' + cn[0].min;
      }
      else {
        if (cn[0].amount == 0) {
          this.ChangeNowEnDis = 0;
          this.changeNowCss.style.color = 'red'
          this.changeNowCss.style.fontSize = 'large'
          this.ChangeNowValue = 'Not Available'
        }
        else {
          this.ChangeNowValue = (cn[0].amount).toFixed(8)
          // console.log("\n-------")
          this.ChangeNowEnDis = 1;
          this.changeNowCss.style.color = '#00c162'
          this.ChangeNowValue = (cn[0].amount).toFixed(8)
          this.ChangeNowValue1 = (cn[0].amount).toFixed(8)
        }
      }
    }
    }, (e) => {
      this.ChangeNowEnDis = 0;
      this.changeNowCss.style.color = 'red'
      this.changeNowCss.style.fontSize = 'large'
      this.ChangeNowValue = 'Not Available'
    }, () => {

    })


    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=changelly&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((cl: any) => {

      // console.log('changelly min value-----' + cl[0])

      if (cl[0] == ' ' || cl[0] == undefined || cl[0] == null) {
        // console.log('in blank changelly')
        this.ChangellyEnDis = 0;
        this.changellyCss.style.color = 'red'
        this.changellyCss.style.fontSize = 'large'
        this.changellyCalVal = 'Not Available'
      }
      else{
      if (this.usernameElement.nativeElement.value < cl[0].min) {
        // console.log("\n----")
        this.ChangellyEnDis = 0;
        this.changellyCss.style.color = 'red'
        this.changellyCalVal = 'Min : ' + cl[0].min;
      }
      else {
        if (cl[0].amount == 0) {
          this.ChangellyEnDis = 0;
          this.changellyCss.style.color = 'red'
          this.changellyCss.style.fontSize = 'large'
          this.changellyCalVal = 'Not Available'
        }
         else {
          this.changellyCalVal = (cl[0].amount).toFixed(8)
          // console.log("\n-------")
          this.ChangellyEnDis = 1;
          this.changellyCss.style.color = '#00c162'
          this.changellyCalVal = (cl[0].amount).toFixed(8)
          this.changellyCalVal1 = (cl[0].amount).toFixed(8)
        }

      }
    }
    }, (e) => {
      this.ChangellyEnDis = 0;
      this.changellyCss.style.color = 'red'
      this.changellyCss.style.fontSize = 'large'
      this.changellyCalVal = 'Not Available'
    }, () => {

    })


    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=exolix&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((ex: any) => {

      if (ex[0] == ' ' || ex[0] == undefined || ex[0] == null) {
        this.DisabledExolix = 0;
        this.exolicCalVal = 'Not Available';
        this.exolixCss.style.color = 'red'
        this.exolixCss.style.fontSize = 'large'
      }
      else{
      if (this.usernameElement.nativeElement.value < ex[0].min) {
        // console.log("\n----")
        this.DisabledExolix = 0;
        this.exolixCss.style.color = 'red'
        this.exolicCalVal = 'Min : ' + ex[0].min;
      }
      else {
        if (ex[0].amount == 0) {
          this.DisabledExolix = 0;
          this.exolicCalVal = 'Not Available';
          this.exolixCss.style.color = 'red'
          this.exolixCss.style.fontSize = 'large'

        } else {
          this.exolicCalVal = (ex[0].amount).toFixed(8)
          // console.log("\n-------")
          this.DisabledExolix = 1;
          this.exolixCss.style.color = '#00c162'
          this.exolicCalVal = (ex[0].amount).toFixed(8)
          this.exolicCalVal1 = (ex[0].amount).toFixed(8)
        }
      }
    }
    }, (e) => {
      this.DisabledExolix = 0;
      this.exolicCalVal = 'Not Available';
      this.exolixCss.style.color = 'red'
      this.exolixCss.style.fontSize = 'large'
    }, () => {

    })

    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=switchain&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((sc: any) => {

      if (sc[0] == ' ' || sc[0] == undefined || sc[0] == null) {
        this.swithainEnDis = 0;
        this.switchainCalVal = 'Not Available';
        this.switchainCss.style.color = 'red'
        this.switchainCss.style.fontSize = 'large'
      }
      else{
      if (this.usernameElement.nativeElement.value < sc[0].min) {
        // console.log("switch chain if 1 --------------------------------" + sc[0].min)
        this.swithainEnDis = 0;
        this.switchainCss.style.color = 'red'
        this.switchainCalVal = 'Min : ' + sc[0].min;
      }
      else {
        if (sc[0].amount == 0) {
          this.swithainEnDis = 0;
          this.switchainCalVal = 'Not Available';
          this.switchainCss.style.color = 'red'
          this.switchainCss.style.fontSize = 'large'

        } else {
          this.switchainCalVal = (sc[0].amount).toFixed(8)
          // console.log("\n-------" + JSON.stringify(sc))
          this.swithainEnDis = 1;
          this.switchainCss.style.color = '#00c162'
          this.switchainCalVal = (sc[0].amount).toFixed(8)
          this.switchainCalVal1 = (sc[0].amount).toFixed(8)
        }

      }
    }
    }, (e) => {
      this.swithainEnDis = 0;
      this.switchainCalVal = 'Not Available';
      this.switchainCss.style.color = 'red'
      this.switchainCss.style.fontSize = 'large'
    }, () => {

    })

    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=letsexchange&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((le: any) => {

      if (le[0] == ' ' || le[0] == undefined || le[0] == null) {
        this.LetsExchangeEnDis = 0;
        this.letsexchangeCalVal = 'Not Available'
        this.letsExchangeCss.style.color = 'red'
        this.letsExchangeCss.style.fontSize = 'large'
      }else{

      if (this.usernameElement.nativeElement.value < le[0].min) {
        // console.log("switch chain if 1 --------------------------------" + le[0].min)
        this.LetsExchangeEnDis = 0;
        this.letsExchangeCss.style.color = 'red'
        this.letsexchangeCalVal = 'Min : ' + le[0].min;
      }
      else {
        if (le[0].amount == 0) {
          this.LetsExchangeEnDis = 0;
          this.letsexchangeCalVal = 'Not Available'
          this.letsExchangeCss.style.color = 'red'
          this.letsExchangeCss.style.fontSize = 'large'

        } else {
          this.letsexchangeCalVal = le[0].amount.toFixed(8)
          // console.log("\n-------" + JSON.stringify(le))
          this.LetsExchangeEnDis = 1;
          this.letsExchangeCss.style.color = '#00c162'
          this.letsexchangeCalVal = le[0].amount.toFixed(8)
          this.letsexchangeCalVal1 = le[0].amount.toFixed(8)
        }
      }
    }
    }, (e) => {
      this.LetsExchangeEnDis = 0;
      this.letsexchangeCalVal = 'Not Available'
      this.letsExchangeCss.style.color = 'red'
      this.letsExchangeCss.style.fontSize = 'large'

    }, () => {

    })


    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=nexchange&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((n: any) => {
      if (n[0] == ' ' || n[0] == undefined || n[0] == null) {
        this.NExchngerEnDis = 0;
        this.NexchangeCalVal = 'Not Available';
        this.NexchangeCss.style.color = 'red'
        this.NexchangeCss.style.fontSize = 'large'
      }else{
      if (this.usernameElement.nativeElement.value < n[0].min) {
        // console.log("switch chain if 1 --------------------------------" + n[0].min)
        this.NExchngerEnDis = 0;
        this.NexchangeCss.style.color = 'red'
        this.NexchangeCalVal = 'Min : ' + n[0].min;
      }
      else {
        if (n[0].amount == 0) {
          this.NExchngerEnDis = 0;
          this.NexchangeCalVal = 'Not Available';
          this.NexchangeCss.style.color = 'red'
          this.NexchangeCss.style.fontSize = 'large'

        } else {
          this.NexchangeCalVal = (n[0].amount).toFixed(8)
          // console.log("\n-------" + JSON.stringify(n))
          this.NExchngerEnDis = 1;
          this.NexchangeCss.style.color = '#00c162'
          this.NexchangeCalVal = (n[0].amount).toFixed(8)
          this.NexchangeCalVal1 = (n[0].amount).toFixed(8)
        }
      }
    }
    }, (e) => {
      this.NExchngerEnDis = 0;
      this.NexchangeCalVal = 'Not Available';
      this.NexchangeCss.style.color = 'red'
      this.NexchangeCss.style.fontSize = 'large'

    }, () => {

    })


    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=binance&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((n: any) => {
      // console.log("binance "+JSON.stringify(n))
      if (n[0] == ' ' || n[0] == undefined || n[0] == null) {
        this.BinanceEnDis = 0;
        this.binanceCalVal = 'Not Available';
        this.binanceCss.style.color = 'red'
        this.binanceCss.style.fontSize = 'large'
      }else{
      if (this.usernameElement.nativeElement.value < n[0].min) {
        // console.log("switch chain if 1 --------------------------------" + n[0].min)
        this.BinanceEnDis = 0;
        this.binanceCss.style.color = 'red'
        this.binanceCalVal = 'Min : ' + n[0].min;
      }
      else {
        if (n[0].amount == 0) {
          this.BinanceEnDis = 0;
          this.binanceCalVal = 'Not Available';
          this.binanceCss.style.color = 'red'
          this.binanceCss.style.fontSize = 'large'
        } else {
          this.binanceCalVal = (n[0].amount).toFixed(8)
          // console.log("\n-------" + JSON.stringify(n))
          this.BinanceEnDis = 1;
          this.binanceCss.style.color = '#00c162'
          this.binanceCalVal = (n[0].amount).toFixed(8)
          this.binanceCalVal1 = (n[0].amount).toFixed(8)
        }
      }
    }
    }, (e) => {
      this.BinanceEnDis = 0;
      this.binanceCalVal = 'Not Available';
      this.binanceCss.style.color = 'red'
      this.binanceCss.style.fontSize = 'large'
    }, () => {

    })

    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=changehero&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((cch: any) => {
      if (cch[0] == ' ' || cch[0] == undefined || cch[0] == null){
        this.changeheroEnDis = 0;
        this.changeheroCalVal = 'Not Available';
        this.changeHeroCss.style.color = 'red'
        this.changeHeroCss.style.fontSize = 'large'
      }else{
      if (this.usernameElement.nativeElement.value < cch[0].min) {
        // console.log("switch chain if 1 --------------------------------" + cch[0].min)
        this.changeheroEnDis = 0;
        this.changeHeroCss.style.color = 'red'
        this.changeheroCalVal = 'Min : ' + cch[0].min;
      }
      else {
        if (cch[0].amount == 0) {
          this.changeheroEnDis = 0;
          this.changeheroCalVal = 'Not Available';
          this.changeHeroCss.style.color = 'red'
          this.changeHeroCss.style.fontSize = 'large'
        } else {
          this.changeheroCalVal = cch[0].amount.toFixed(8)
          // console.log("\n-------" + JSON.stringify(cch))
          this.changeheroEnDis = 1;
          this.changeHeroCss.style.color = '#00c162'
          this.changeheroCalVal = (cch[0].amount).toFixed(8)
          this.changeheroCalVal1 = (cch[0].amount).toFixed(8)
        }
      }
    }
    }, (e) => {
      this.changeheroEnDis = 0;
      this.changeheroCalVal = 'Not Available';
      this.changeHeroCss.style.color = 'red'
      this.changeHeroCss.style.fontSize = 'large'
    }, () => {

    })


    this.http.get(`https://api.swapspace.co/api/amounts?from=btc&to=eth&amount=1&partner=foxexchange&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((fox: any) => {

      if (fox[0] == ' ' || fox[0] == undefined || fox[0] == null){
        this.foxexchangeEnDis = 0;
        this.foxexchangeCss.style.color = 'red'
        this.foxexchangeCss.style.fontSize = 'large'
        this.foxexchangeCalVal = 'Not Available'
      }
      else{
      if (this.usernameElement.nativeElement.value < fox[0].min) {
        // console.log("foxexchange if 1 --------------------------------" + fox[0].min)
        this.foxexchangeEnDis = 0;
        this.foxexchangeCss.style.color = 'red'
        this.foxexchangeCalVal = 'Min : ' + fox[0].min;
      }
      else {
        if (fox[0].amount == 0) {
          this.foxexchangeEnDis = 0;
          this.foxexchangeCss.style.color = 'red'
          this.foxexchangeCss.style.fontSize = 'large'
          this.foxexchangeCalVal = 'Not Available'
        }
        else {
          this.foxexchangeCalVal = fox[0].amount.toFixed(8)
          // console.log("\n foxexchange -------" + JSON.stringify(fox))
          this.foxexchangeEnDis = 1;
          this.foxexchangeCss.style.color = '#00c162'
          this.foxexchangeCalVal = fox[0].amount.toFixed(8)
          this.foxexchangeCalVal1 = fox[0].amount.toFixed(8)
        }
      }
    }
    }, (e) => {
      this.foxexchangeEnDis = 0;
      this.foxexchangeCss.style.color = 'red'
      this.foxexchangeCss.style.fontSize = 'large'
      this.foxexchangeCalVal = 'Not Available'

    }, () => {

    })
  }


  open(content) {
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  open2(content) {
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.toastService.showInfo("Testing Version","Warning");


    // this.name = ''


    // ------------------------------------- get coins data from mysql -------------------------------------------------------------------------

    this.http.get(`https://api.swapspace.co/api/currencies`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((a: any) => {
      a.forEach(b => {
        this.getCoinData_A.push(b.code)
        this.getCoinData_B.push(b.code)
      })

    }, (e) => {

    }, () => {
      this.getCoinData_B.push('bigb');
      this.getCoinData_A.forEach(a => {
        this.mlist.push(a)
      })
      this.getCoinData_B.forEach(a => {
        this.mlistB.push(a)
      })
    })
    this.firstselect = 'BTC';
    this.secondselect = 'ETH';


    // ------------------------------------------------------------------------------------------------------------------------------------------
  }   

  // --------------------------------------------------------------------------------------------------------------------------------------------
  search() {
    this.getCoinData_A = []
    //push mlist(all coins data) to getCoinData_A array
    this.mlist.forEach(a => {
      this.getCoinData_A.push(a)
      return a
    })
    if (this.secondselect == 'BIGB') {
      this.firstselect = 'INR'
      this.getCoinData_A = []
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
    }
    if (this.secondselect == "BIGB") {
      if (this.firstselect == 'inr' || this.firstselect == 'bnb' || this.firstselect == 'btc' || this.firstselect == 'trx' || this.firstselect == 'eth') {
        this.getCoinData_A = []
        this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      }
      this.getCoinData_A = []
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      this.bigb_Conversion(this.firstselect);
    }
    if (this.name.toLocaleLowerCase() == '' && this.secondselect == 'BIGB') {
      this.getCoinData_A = []
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
    }else if (this.name.toLocaleLowerCase() == ''){   //if we not type anything in search box then also all coin list will show
      this.mlist.forEach(a => {
        this.getCoinData_A.push(a)
        return a
      })
    }
    //match typed coin in search list
    this.getCoinData_A = this.getCoinData_A.filter((res: any) => {
      return res.match(this.name.toLocaleLowerCase())
    });
  }

  search2() {
    this.getCoinData_B = []
    this.mlistB.forEach(a => {
      this.getCoinData_B.push(a)
      return a
    })
    if (this.secondselect == 'BIGB') {
      // console.log("hi same secondselect")
      this.defaultVal2 = 0;
      this.firstselect = 'INR'
      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')

      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
    }

    if (this.name2.toLocaleLowerCase() == '') {
      this.mlistB.forEach(a => {
        this.getCoinData_B.push(a)
        return a
      })
    }
    this.getCoinData_B = this.getCoinData_B.filter((res: any) => {
      return res.match(this.name2.toLocaleLowerCase())
    }
    );
  }

  selectclick(data1: any) {

    // console.log('selectclick' + this.secondselect + "\n" + data1)

    this.mlist.forEach(a => {
      this.getCoinData_A.push(a)
    })

    this.firstselect = data1.toUpperCase();
    this.modalService.dismissAll()
    this.name = ''
    if (this.secondselect == "BIGB") {
      if (data1 == 'inr' || data1 == 'bnb' || data1 == 'btc' || data1 == 'trx' || data1 == 'eth') {
        this.getCoinData_A = []
        this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
        // this.getCoinData_A.push('inr')

      }
      this.bigb_Conversion(this.firstselect)
    }
    else {
      this.binanceExchanger();
      this.exolixExchanger();
      this.changeNowExchanger();
      this.simpleSwapExchanger();
      this.stealthEXExchanger();
      this.godexExchanger();
      this.changellyExchanger();
      this.switChainExchanger();
      this.foxExchange();
      this.letsExchange();
      this.nExchange();
      this.changeHeroExchange();
    }


    if (this.firstselect == this.secondselect) {
      if (this.firstselect != 'BTC') {
        this.secondselect = 'BTC'
      }
      else {
        this.secondselect = 'ETH'
      }
    }
  }

  selectclick2(data1: any) {

    this.mlistB.forEach(a => {
      this.getCoinData_B.push(a)
    })
    this.secondselect = data1.toUpperCase();
    this.modalService.dismissAll()
    this.name2 = ''
    if (data1 == 'bigb') {
      // this.defaultVal2 = 0;
      this.firstselect = 'INR'
      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')

      // this.inputCalVal= 0
      this.bigb_Conversion(data1);

      this.BinanceEnDis = 0;
      this.DisabledExolix = 0;
      this.ChangeNowEnDis = 0;
      this.SimSwapEnDis = 0;
      this.StealthEXEnDis = 0;
      this.GodexEnDis = 0;
      this.ChangellyEnDis = 0;
      this.swithainEnDis = 0;
      this.LetsExchangeEnDis = 0;
      this.NExchngerEnDis = 0;
      this.changeheroEnDis = 0;
      this.foxexchangeEnDis = 0;

      this.binanceCss.style.color = 'red';
      this.exolixCss.style.color = 'red';
      this.changeNowCss.style.color = 'red';
      this.simpleSwapCss.style.color = 'red';
      this.stealthEXCss.style.color = 'red';
      this.godexCss.style.color = 'red';
      this.changellyCss.style.color = 'red';
      this.switchainCss.style.color = 'red';
      this.letsExchangeCss.style.color = 'red';
      this.NexchangeCss.style.color = 'red';
      this.changeHeroCss.style.color = 'red';
      this.foxexchangeCss.style.color = 'red';

      this.binanceCss.style.fontSize = 'large';
      this.exolixCss.style.fontSize = 'large';
      this.changeNowCss.style.fontSize = 'large';
      this.simpleSwapCss.style.fontSize = 'large';
      this.stealthEXCss.style.fontSize = 'large';
      this.godexCss.style.fontSize = 'large';
      this.changellyCss.style.fontSize = 'large';
      this.switchainCss.style.fontSize = 'large';
      this.letsExchangeCss.style.fontSize = 'large';
      this.NexchangeCss.style.fontSize = 'large';
      this.changeHeroCss.style.fontSize = 'large';
      this.foxexchangeCss.style.fontSize = 'large';

      this.godexCalVal = 'Not Available'
      this.binanceCalVal = 'Not Available';
      this.ChangeNowValue = 'Not Available';
      this.simpleSwapCal = 'Not Available';
      this.exolicCalVal = 'Not Available';
      this.stealthExValue = 'Not Available';
      this.godexCalVal = 'Not Available';
      this.changellyCalVal = 'Not Available';
      this.switchainCalVal = 'Not Available';
      this.letsexchangeCalVal = 'Not Available';
      this.NexchangeCalVal = 'Not Available';
      this.changeheroCalVal = 'Not Available';
      this.foxexchangeCalVal = 'Not Available';


      this.cotoexDiv = 1;

    }
    else {
      this.cotoexDiv = 0;
      if (this.firstselect == 'INR') {
        this.firstselect = 'ETH';
      }

      this.getCoinData_A = []
      this.getCoinData_B = []
      this.http.get(`https://api.swapspace.co/api/currencies`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
        }
      }).subscribe((a: any) => {
        a.forEach(b => {
          this.getCoinData_A.push(b.code)
          this.getCoinData_B.push(b.code)
        })

      }, (e) => {

      }, () => {
        this.getCoinData_A.forEach(a => {
          this.mlist.push(a)
        })
        this.getCoinData_B.forEach(a => {
          this.mlistB.push(a)
        })

      })
      if (this.firstselect == this.secondselect) {
        if (this.firstselect != 'ETH') {
          this.secondselect = 'ETH'
        }
        else {
          this.secondselect = 'BTC'
        }

      }
      this.binanceExchanger();
      this.exolixExchanger();
      this.changeNowExchanger();
      this.simpleSwapExchanger();
      this.stealthEXExchanger();
      this.godexExchanger();
      this.changellyExchanger();
      this.switChainExchanger();
      this.foxExchange();
      this.letsExchange();
      this.nExchange();
      this.changeHeroExchange();

    }
  }

  // ---------------------------------------------------------------------- End of change function of dropdown 2 -------------------------------------------------------------------------------------------------------------------------------------------------------


  // --------------------------------------------------------------- input function of first dropdown ------------------------------------------------------------------------------------------------------------------------------------------------------------------
  myFunction() {
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()
    if (this.secondselect != 'BIGB') {
      this.binanceExchanger();
      this.exolixExchanger();
      this.changeNowExchanger();
      this.stealthEXExchanger();
      this.simpleSwapExchanger();
      this.godexExchanger();
      this.changellyExchanger();
      this.switChainExchanger();
      this.foxExchange();
      this.letsExchange();
      this.nExchange();
      this.changeHeroExchange();
    }
    else {
      this.bigb_Conversion(this.firstselect);
    }
    //  function calling 
  }

  //  ----------------------------------------------------------- end of input function of dropdown 1-----------------------------------------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------- Exchange button function ----------------------------------------------------------------------------------------------------------------------- 
  submitExchange(table_id) {
    // console.log('event exchange' + table_id)
    this.route.navigateByUrl(`/step2/${table_id}/${this.firstselect}/${this.secondselect}/${this.usernameElement.nativeElement.value}`)
  }
  //  ------------------------------------------------------------- end of exchange button ----------------------------------------------------------------------------------

  binanceExchanger() {

    this.binanceCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=binance&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((n: any) => {
      this.BinanceEnDis = 0;
      this.binanceCalVal = 'Not Available';
      this.binanceCss.style.color = 'red'
      this.binanceCss.style.fontSize = 'large'
      this.binanceCalVal1 = 0

      if (this.usernameElement.nativeElement.value < n[0].min) {

        // console.log("switch chain if 1 --------------------------------" + n[0].min)
        this.BinanceEnDis = 0;
        this.binanceCss.style.color = 'red'
        this.binanceCalVal = 'Min : ' + n[0].min;
        this.binanceCalVal1 = 0
      }
      else {

        if (n[0].amount == 0) {
          this.BinanceEnDis = 0;
          this.binanceCalVal = 'Not Available';
          this.binanceCss.style.color = 'red'
          this.binanceCss.style.fontSize = 'large'
          this.binanceCalVal1 = 0
        } else {
          this.binanceCalVal = (n[0].amount).toFixed(8)
          // console.log("\n-------" + JSON.stringify(n))
          this.BinanceEnDis = 1;
          this.binanceCss.style.color = '#00c162'
          this.binanceCalVal = (n[0].amount).toFixed(8)
          this.binanceCalVal1 = (n[0].amount).toFixed(8)
        }
      }
    }, (e) => {

      this.BinanceEnDis = 0;
      this.binanceCalVal = 'Not Available';
      this.binanceCss.style.color = 'red'
      this.binanceCss.style.fontSize = 'large'
      this.binanceCalVal1 = 0

    }, () => {

      if (this.binanceCalVal == 0) {
        this.BinanceEnDis = 0;
        this.binanceCalVal = 'Not Available';
        this.binanceCss.style.color = 'red'
        this.binanceCss.style.fontSize = 'large'
        this.binanceCalVal1 = 0
      }

    })
  }

  //  -------------------------------------------------------- Exolix function -----------------------------------------------------------

  exolixExchanger() {
    this.exolicCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=exolix&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((ex: any) => {
      this.DisabledExolix = 0;
      this.exolicCalVal = 'Not Available';
      this.exolixCss.style.color = 'red'
      this.exolixCss.style.fontSize = 'large'
      this.exolicCalVal1 = 0
      if (this.usernameElement.nativeElement.value < ex[0].min) {
        // console.log("\n----")
        this.DisabledExolix = 0;
        this.exolixCss.style.color = 'red'
        this.exolicCalVal = 'Min : ' + ex[0].min;
        this.exolicCalVal1 = 0
      }
      else {
        if (ex[0].amount == 0) {
          this.DisabledExolix = 0;
          this.exolicCalVal = 'Not Available';
          this.exolixCss.style.color = 'red'
          this.exolixCss.style.fontSize = 'large'
          this.exolicCalVal1 = 0

        } else {
          this.exolicCalVal = (ex[0].amount).toFixed(8)
          // console.log("\n-------")
          this.DisabledExolix = 1;
          this.exolixCss.style.color = '#00c162'
          this.exolicCalVal = (ex[0].amount).toFixed(8)
          this.exolicCalVal1 = (ex[0].amount).toFixed(8)
        }
      }
    }, (e) => {
      this.DisabledExolix = 0;
      this.exolicCalVal = 'Not Available';
      this.exolixCss.style.color = 'red'
      this.exolixCss.style.fontSize = 'large'
      this.exolicCalVal1 = 0
    }, () => {

    })

  }

  // -------------------------------------------------- End of Exolix --------------------------------------------------------------------


  // -------------------------------------------------- switchain ------------------------------------------------------------------
  switChainExchanger() {
    this.switchainCalVal = 0
    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=switchain&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((sc: any) => {
      this.swithainEnDis = 0;
      this.switchainCalVal = 'Not Available';
      this.switchainCss.style.color = 'red'
      this.switchainCss.style.fontSize = 'large'
      this.switchainCalVal1 = 0
      if (this.usernameElement.nativeElement.value < sc[0].min) {
        // console.log("switch chain if 1 --------------------------------" + sc[0].min)
        this.swithainEnDis = 0;
        this.switchainCss.style.color = 'red'
        this.switchainCalVal = 'Min : ' + sc[0].min;
        this.switchainCalVal1 = 0
      }
      else {
        if (sc[0].amount == 0) {
          this.swithainEnDis = 0;
          this.switchainCalVal = 'Not Available';
          this.switchainCss.style.color = 'red'
          this.switchainCss.style.fontSize = 'large'
          this.switchainCalVal1 = 0

        } else {
          this.switchainCalVal = (sc[0].amount).toFixed(8)
          // console.log("\n-------" + JSON.stringify(sc))
          this.swithainEnDis = 1;
          this.switchainCss.style.color = '#00c162'
          this.switchainCalVal = (sc[0].amount).toFixed(8)
          this.switchainCalVal1 = (sc[0].amount).toFixed(8)
        }
      }
    }, (e) => {
      this.swithainEnDis = 0;
      this.switchainCalVal = 'Not Available';
      this.switchainCss.style.color = 'red'
      this.switchainCss.style.fontSize = 'large'
      this.switchainCalVal1 = 0
    }, () => {

    })
  }
  // --------------------------------------------------------------------------------------------------------------------------------


  // --------------------------------------------------- changelly ------------------------------------------------------------------

  changellyExchanger() {
    this.changellyCalVal = 0
    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=changelly&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((cl: any) => {
      this.ChangellyEnDis = 0;
      this.changellyCss.style.color = 'red'
      this.changellyCss.style.fontSize = 'large'
      this.changellyCalVal = 'Not Available'
      this.changellyCalVal1 = 0
      if (this.usernameElement.nativeElement.value < cl[0].min) {
        // console.log("\n----")
        this.ChangellyEnDis = 0;
        this.changellyCss.style.color = 'red'
        this.changellyCalVal = 'Min : ' + cl[0].min;
        this.changellyCss.style.fontSize = 'large'
        this.changellyCalVal1 = 0
      }
      else {
        if (cl[0].amount == 0) {
          this.ChangellyEnDis = 0;
          this.changellyCss.style.color = 'red'
          this.changellyCss.style.fontSize = 'large'
          this.changellyCalVal = 'Not Available'
          this.changellyCalVal1 = 0
        }
        else {
          this.changellyCalVal = (cl[0].amount).toFixed(8)
          // console.log("\n-------")
          this.ChangellyEnDis = 1;
          this.changellyCss.style.color = '#00c162'
          this.changellyCss.style.fontSize = 'large'
          this.changellyCalVal = (cl[0].amount).toFixed(8)
          this.changellyCalVal1 = (cl[0].amount).toFixed(8)
        }

      }
    }, (e) => {
      this.ChangellyEnDis = 0;
      this.ChangellyEnDis = 0;
      this.changellyCss.style.color = 'red'
      this.changellyCss.style.fontSize = 'large'
      this.changellyCalVal = 'Not Available'
      this.changellyCalVal1 = 0
    }, () => {

    })
  }

  // --------------------------------------------------------------------------------------------------------------------------------

  //  ------------------------------------------------------------ Change Now ------------------------------------------------------------

  changeNowExchanger() {
    this.ChangeNowValue = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=changenow&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((cn: any) => {
      this.ChangeNowEnDis = 0;
      this.changeNowCss.style.color = 'red'
      this.changeNowCss.style.fontSize = 'large'
      this.ChangeNowValue = 'Not Available'
      this.ChangeNowValue1 = 0
      if (this.usernameElement.nativeElement.value < cn[0].min) {
        // console.log("\n----")
        this.ChangeNowEnDis = 0;
        this.changeNowCss.style.color = 'red'
        this.ChangeNowValue = 'Min : ' + cn[0].min;
        this.ChangeNowValue1 = 0
      }
      else {
        if (cn[0].amount == 0) {
          this.ChangeNowEnDis = 0;
          this.changeNowCss.style.color = 'red'
          this.changeNowCss.style.fontSize = 'large'
          this.ChangeNowValue = 'Not Available'
          this.ChangeNowValue1 = 0
        }
        else {
          this.ChangeNowValue = (cn[0].amount).toFixed(8)
          // console.log("\n-------")
          this.ChangeNowEnDis = 1;
          this.changeNowCss.style.color = '#00c162'
          this.ChangeNowValue = (cn[0].amount).toFixed(8)
          this.ChangeNowValue1 = (cn[0].amount).toFixed(8)
        }
      }
    }, (e) => {
      this.ChangeNowEnDis = 0;
      this.changeNowCss.style.color = 'red'
      this.changeNowCss.style.fontSize = 'large'
      this.ChangeNowValue = 'Not Available'
      this.ChangeNowValue1 = 0
    }, () => {

    })

  }
  //  --------------------------------------------------------- End of Change Now------------------------------------------------


  // ------------------------------------------------------------- swapspace/Godex ----------------------------------------------

  godexExchanger() {
    this.godexCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=godex&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((ab: any) => {
      this.GodexEnDis = 0;
      this.godexCss.style.color = 'red';
      this.godexCss.style.fontSize = 'large';
      this.godexCalVal = 'Not Available'
      this.godexCalVal1 = 0
      if (this.usernameElement.nativeElement.value < ab[0].min) {
        // console.log("\n----")
        this.GodexEnDis = 0;
        this.godexCss.style.color = 'red'
        this.godexCalVal = 'Min : ' + ab[0].min;
        this.godexCalVal1 = 0
      }
      else {
        if (ab[0].amount == 0) {
          this.GodexEnDis = 0;
          this.godexCss.style.color = 'red';
          this.godexCss.style.fontSize = 'large';
          this.godexCalVal = 'Not Available'
          this.godexCalVal1 = 0
        }
        else {
          this.godexCalVal = (ab[0].amount).toFixed(8)
          // console.log("\n-------")
          this.GodexEnDis = 1;
          this.godexCss.style.color = '#00c162'
          this.godexCalVal = (ab[0].amount).toFixed(8)
          this.godexCalVal1 = (ab[0].amount).toFixed(8)
        }
      }
    }, (e) => {
      this.GodexEnDis = 0;
      this.godexCss.style.color = 'red';
      this.godexCss.style.fontSize = 'large';
      this.godexCalVal = 'Not Available'
      this.godexCalVal1 = 0
    }, () => {

    })
  }


  // ----------------------------------------------------------------------------------------------------------------------------

  //  -------------------------------------------------------- Simple Swap Function ---------------------------------------------
  simpleSwapExchanger() {
    this.simpleSwapCal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()
    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=simpleswap&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((sswap: any) => {
      this.SimSwapEnDis = 0;
      this.simpleSwapCss.style.color = 'red';
      this.simpleSwapCss.style.fontSize = 'large';
      this.simpleSwapCal = 'Not Available'
      this.simpleSwapCal1 = 0
      if (this.usernameElement.nativeElement.value < sswap[0].min) {
        // console.log("\n----")
        this.SimSwapEnDis = 0;
        this.simpleSwapCss.style.color = 'red'
        this.simpleSwapCal = 'Min : ' + sswap[0].min;
        this.simpleSwapCal1 = 0
      }
      else {
        if (sswap[0].amount == 0) {
          this.SimSwapEnDis = 0;
          this.simpleSwapCss.style.color = 'red';
          this.simpleSwapCss.style.fontSize = 'large';
          this.simpleSwapCal = 'Not Available'
          this.simpleSwapCal1 = 0
        }
        else {
          this.simpleSwapCal = sswap[0].amount.toFixed(8)
          // console.log("\n-------")
          this.SimSwapEnDis = 1;
          this.simpleSwapCss.style.color = '#00c162'
          this.simpleSwapCal = sswap[0].amount.toFixed(8)
          this.simpleSwapCal1 = sswap[0].amount.toFixed(8)
        }
      }
    }, (e) => {
      this.SimSwapEnDis = 0;
      this.simpleSwapCss.style.color = 'red';
      this.simpleSwapCss.style.fontSize = 'large';
      this.simpleSwapCal = 'Not Available'
      this.simpleSwapCal1 = 0
    }, () => {

    })

  }
  // ---------------------------------------------------------- End of Simple Swap ----------------------------------------------

  // ---------------------------------------------------------- stealthEX --------------------------------------------------------
  stealthEXExchanger() {
    this.stealthExValue = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=stealthex&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((stealthex: any) => {
      this.StealthEXEnDis = 0;
      this.stealthEXCss.style.color = 'red'
      this.stealthExValue = 'Not Available'
      this.stealthEXCss.style.fontSize = 'large'
      this.stealthExValue1 = 0
      if (this.usernameElement.nativeElement.value < stealthex[0].min) {
        // console.log("\n----")
        this.StealthEXEnDis = 0;
        this.stealthEXCss.style.color = 'red'
        this.stealthExValue = 'Min : ' + stealthex[0].min;
        this.stealthExValue1 = 0
      }
      else {
        if (stealthex[0].amount == 0) {
          this.StealthEXEnDis = 0;
          this.stealthEXCss.style.color = 'red'
          this.stealthExValue = 'Not Available'
          this.stealthEXCss.style.fontSize = 'large'
          this.stealthExValue1 = 0
        }
        else {
          this.stealthExValue = (stealthex[0].amount).toFixed(8)
          // console.log("\n-------")
          this.StealthEXEnDis = 1;
          this.stealthEXCss.style.color = '#00c162'
          this.stealthExValue = (stealthex[0].amount).toFixed(8)
          this.stealthExValue1 = (stealthex[0].amount).toFixed(8)
        }
      }
    }, (e) => {
      this.StealthEXEnDis = 0;
      this.stealthEXCss.style.color = 'red'
      this.stealthExValue = 'Not Available'
      this.stealthEXCss.style.fontSize = 'large'
      this.stealthExValue1 = 0
    }, () => {

    })
  }


  foxExchange() {
    this.foxexchangeCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=foxexchange&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((fox: any) => {
      this.foxexchangeEnDis = 0;
      this.foxexchangeCss.style.color = 'red'
      this.foxexchangeCss.style.fontSize = 'large'
      this.foxexchangeCalVal = `Not Available`
      this.foxexchangeCalVal1 = 0
      if (this.usernameElement.nativeElement.value < fox[0].min) {
        // console.log("switch chain if 1 --------------------------------" + fox[0].min)
        this.foxexchangeEnDis = 0;
        this.foxexchangeCss.style.color = 'red'
        this.foxexchangeCalVal = 'Min : ' + fox[0].min;
        this.foxexchangeCalVal1 = 0
      }
      else {
        if (fox[0].amount == 0) {
          this.foxexchangeEnDis = 0;
          this.foxexchangeCss.style.color = 'red'
          this.foxexchangeCss.style.fontSize = 'large'
          this.foxexchangeCalVal = `Not Available`
          this.foxexchangeCalVal1 = 0
        }
        else {
          this.foxexchangeCalVal = fox[0].amount.toFixed(8)
          // console.log("\n foxexchange -------" + JSON.stringify(fox))
          this.foxexchangeEnDis = 1;
          this.foxexchangeCss.style.color = '#00c162'
          this.foxexchangeCalVal = fox[0].amount.toFixed(8)
          this.foxexchangeCalVal1 = fox[0].amount.toFixed(8)
        }
      }
    }, (e) => {
      this.foxexchangeEnDis = 0;
      this.foxexchangeCss.style.color = 'red'
      this.foxexchangeCss.style.fontSize = 'large'
      this.foxexchangeCalVal = `Not Available`
      this.foxexchangeCalVal1 = 0
    }, () => {

    })
  }

  nExchange() {
    this.NexchangeCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=nexchange&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((n: any) => {
      this.NExchngerEnDis = 0;
      this.NexchangeCalVal = 'Not Available';
      this.NexchangeCss.style.color = 'red'
      this.NexchangeCss.style.fontSize = 'large'
      this.NexchangeCalVal1 = 0
      if (this.usernameElement.nativeElement.value < n[0].min) {
        // console.log("switch chain if 1 --------------------------------" + n[0].min)
        this.NExchngerEnDis = 0;
        this.NexchangeCss.style.color = 'red'
        this.NexchangeCalVal = 'Min : ' + n[0].min;
        this.NexchangeCalVal1 = 0
      }
      else {
        if (n[0].amount == 0) {
          this.NExchngerEnDis = 0;
          this.NexchangeCalVal = 'Not Available';
          this.NexchangeCss.style.color = 'red'
          this.NexchangeCss.style.fontSize = 'large'
          this.NexchangeCalVal1 = 0

        } else {
          this.NexchangeCalVal = (n[0].amount).toFixed(8)
          // console.log("\n-------" + JSON.stringify(n))
          this.NExchngerEnDis = 1;
          this.NexchangeCss.style.color = '#00c162'
          this.NexchangeCalVal = (n[0].amount).toFixed(8)
          this.NexchangeCalVal1 = (n[0].amount).toFixed(8)
        }
      }
    }, (e) => {
      this.NExchngerEnDis = 0;
      this.NexchangeCalVal = 'Not Available';
      this.NexchangeCss.style.color = 'red'
      this.NexchangeCss.style.fontSize = 'large'
    }, () => {

    })
  }

  changeHeroExchange() {
    this.changeheroCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=changehero&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((cch: any) => {
      this.changeheroEnDis = 0;
      this.changeHeroCss.style.color = 'red'
      this.changeHeroCss.style.fontSize = 'large'
      this.changeheroCalVal = 'Not Available';
      this.changeheroCalVal1 = 0
      if (this.usernameElement.nativeElement.value < cch[0].min) {
        // console.log("switch chain if 1 --------------------------------" + cch[0].min)
        this.changeheroEnDis = 0;
        this.changeHeroCss.style.color = 'red'
        this.changeheroCalVal = 'Min : ' + cch[0].min;
        this.changeheroCalVal1 = 0
      }
      else {
        if (cch[0].amount == 0) {
          this.changeheroEnDis = 0;
          this.changeHeroCss.style.color = 'red'
          this.changeHeroCss.style.fontSize = 'large'
          this.changeheroCalVal = 'Not Available';
          this.changeheroCalVal1 = 0
        } else {
          //  alert(cch[0].amount.toFixed(8))
          this.changeheroCalVal = cch[0].amount.toFixed(8)
          // console.log("\n-------" + JSON.stringify(cch))
          this.changeheroEnDis = 1;
          this.changeHeroCss.style.color = '#00c162'
          this.changeheroCalVal = (cch[0].amount).toFixed(8)
          this.changeheroCalVal1 = (cch[0].amount).toFixed(8)
        }
      }
    }, (e) => {
      this.changeheroEnDis = 0;
      this.changeHeroCss.style.color = 'red'
      this.changeHeroCss.style.fontSize = 'large'
      this.changeheroCalVal = 'Not Available';
    }, () => {

    })
  }

  letsExchange() {
    this.letsexchangeCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.usernameElement.nativeElement.value}&partner=letsexchange&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((le: any) => {
      this.LetsExchangeEnDis = 0;
      this.letsexchangeCalVal = 'Not Available'
      this.letsExchangeCss.style.color = 'red'
      this.letsExchangeCss.style.fontSize = 'large'
      this.letsexchangeCalVal1 = 0
      if (this.usernameElement.nativeElement.value < le[0].min) {
        // console.log("switch chain if 1 --------------------------------" + le[0].min)
        this.LetsExchangeEnDis = 0;
        this.letsExchangeCss.style.color = 'red'
        this.letsexchangeCalVal = 'Min : ' + le[0].min;
        this.letsexchangeCalVal1 = 0
      }
      else {
        if (le[0].amount == 0) {
          this.LetsExchangeEnDis = 0;
          this.letsexchangeCalVal = 'Not Available'
          this.letsExchangeCss.style.color = 'red'
          this.letsExchangeCss.style.fontSize = 'large'
          this.letsexchangeCalVal1 = 0

        } else {
          this.letsexchangeCalVal = le[0].amount.toFixed(8)
          // console.log("\n-------" + JSON.stringify(le))
          this.LetsExchangeEnDis = 1;
          this.letsExchangeCss.style.color = '#00c162'
          this.letsexchangeCalVal = le[0].amount.toFixed(8)
          this.letsexchangeCalVal1 = le[0].amount.toFixed(8)
        }
      }
    }, (e) => {
      this.LetsExchangeEnDis = 0;
      this.letsexchangeCalVal = 'Not Available'
      this.letsExchangeCss.style.color = 'red'
      this.letsExchangeCss.style.fontSize = 'large'
    }, () => {

    })
  }
  // -----------------------------------------------------------------------------------------------------------------------------


  secondDropdwnVal() {
    if (this.secondselect != "BIGB") {

      this.allVal = [];

      this.allVal = [this.binanceCalVal1, this.foxexchangeCalVal1, this.ChangeNowValue1, this.changeheroCalVal1,
      this.changellyCalVal1, this.letsexchangeCalVal1, this.stealthExValue1, this.godexCalVal1, this.exolicCalVal1,
      this.switchainCalVal1, this.simpleSwapCal1, this.NexchangeCalVal1]

      // console.log('\n allval array' + JSON.stringify(this.allVal))

      var filteredAry: [] = this.allVal.filter(e => e !== 0)

      // console.log('\n filteredAry' + filteredAry)

      this.inputCalVal = Math.max(...filteredAry)
      // console.log('\n 2nd inputcalval' + this.inputCalVal)

      if (filteredAry.length == 0) {
        this.inputCalVal = "Select min amount "
      }
    }
  }



  bigbExchange() {
    // console.log('from: ' + this.firstselect + ' to: ' + this.secondselect + 'amount: ' + this.usernameElement.nativeElement.value)
    // if (this.firstselect == 'INR') {

    // }
    // else {

    // this.route.navigateByUrl(`/bigb-step2/${this.firstselect}/${this.usernameElement.nativeElement.value}`);

    // }
  }

  bigb_Conversion(data) {
    this.toLower1 = String(data).toUpperCase()
    this.inputCalVal = "Not Available"
    this.cotoexcalval = this.inputCalVal
    // console.log('hiii' + data)
    // console.log('this.usernameElement.nativeElement.value ' + this.usernameElement.nativeElement.value)
  //   if (this.firstselect == 'INR') {
  //     // console.log('if INR' + data)
  //     this.http.get(`https://indiapi.bighbull.com/api/getbigrate`).subscribe((a:any) => {
  //       // console.log('done' + a * 75)
  //       var bigbPrice = a * 75        

  //       if(this.usernameElement.nativeElement.value < bigbPrice){
  //         // document.getElementById('dropdown1').innerHTML = `min: ${a * 75}`;
  //         // this.inputCalVal=`min: ${a * 75}`;
  //         this.inputCalVal = "Not Available"
  //         this.cotoexcalval = this.inputCalVal

  //       }else{
  //         // this.inputCalVal = this.usernameElement.nativeElement.value / bigbPrice
  //         this.inputCalVal = "Not Available"
  //         this.cotoexcalval = this.inputCalVal
  //       }

  //     })
  //   }
  //   else {

  //     //-------get first select's one coin's value in $
  //     const r = redstone.getPrice(this.firstselect).then((price) => {
  //       // console.log('redstone price'+price.value)
  //       this.usdConversion = price.value;
  //       this.usdConversion = this.usdConversion * this.usernameElement.nativeElement.value

  //       const options = { method: 'GET', headers: { Accept: 'application/json' } };

  //       this.inputCalVal = this.usdConversion * 74.36
  //       this.cotoexcalval = this.inputCalVal
  //       // console.log('inputCalVal price'+this.inputCalVal)

  //       fetch('https://api.fastforex.io/convert?from=USD&to=INR&amount=1&api_key=5d431750be-eb5943028e-r254da', options)
  //         .then(response => response.json())
  //         .then(response => {
  //           // console.log("response" + JSON.stringify(response))
  //           // console.log("usdconversion" + this.usdConversion)
  //           // console.log("response.result.INR : ", response.result.INR)

  //           //convert value in INR
  //           this.inputCalVal = this.usdConversion *response.result.INR  //currently this api is not working 
  //           this.cotoexcalval = this.inputCalVal
  //           // console.log('inputCalVal price'+this.inputCalVal)

  //         })
  //         .catch(err => console.error("fetch" + err));
  //     })
  //       .catch(err => console.error("getPrice" + err));
  //   }
  }
}