<!DOCTYPE html>
<html lang="en">

<body>
  <div class="banner-block">
    <div class="container"  style="margin-top:5%">
        <!-- <div class="col-md-8"> -->
                
          <div class="row">
            <div class="col-lg-9">
              <div class="content">
                <h2><b>Welcome to <span style="color: gold;">COTOEX</span></b></h2>
                <hr>
              <p>
                Cotoex is an instant cryptocurrency exchange aggregator. 
                Our platform allows customers to  surf through all the swap offers collected from major 
                crypto exchanges just in  one place.
                We  strive to  simplify the exchange process as  much as  possible, making more than 750 
                cryptocurrencies available for fast and registration-free swaps with the greatest rates on  
                the market.<br>
                Our number one priority is  to  save your time — the most precious thing we  have. 
                More than 18  instant exchange services, more than 750 cryptocurrencies and tokens, more than
                 60000 exchange pairs — the whole range of  exchange offers is  now on  one page! You have no  
                 need to  search the rates through different platforms and services comparing their terms and fees
                  anymore — we  do  that for you with no  extra fee!
              </p>              

            </div>
            </div>

            <div class="col-lg-3">
              <div class="contact">
                <h3>Contact At</h3>
                <p>
                  <b>Company Address :</b><br>
                   Office No. 708 C Wing,Teerth Technospace,
                   Bengaluru - Mumbai Highway, Baner,<br>
                    Pune, Maharashtra-411045
                </p>

                <p>
                  <b>Email :</b><br>
                  <label class="bold">techteam@cotoex.com</label>
                </p>
              </div>
            </div>
          </div>
            
    <!-- </div> -->
  </div>
</div>

<footer class="footer">

</footer>
</body>
</html>

