<!DOCTYPE html>
<html lang="en">

<head>

</head>

<body>
    <div class="banner-block">

        <div class="container">

            <div class="offset-md-2 col-md-8">
                <div class="banner-content">
                    <h2>Buy and Sell Cryptocurrency</h2>
                    <h3 style="color: white;">
                        Your Choice Our Best Price
                    </h3>
                    <h4 class="wrapper2">You can buy<span class="wrapper" style=" font-weight: bolder;">BIGB</span>
                        <span style="color: red; font-size: large;" id="demo2">
                            
                            </span>
                    </h4>
                    <br>
                    <!---------------------------------------->
                    <form [formGroup]="selectForm">
                        <div class="container">
                            <div class="row " id="rowid">
                                <div class="col-md-5 m-1">
                                    <!--   col   -->
                                    <div class="input-box input-group mb-3 dropdown" id="dropdown1">
                                        <input type="text" class="form-control inputss" value="{{defaultVal}}" name="s" #valueId (input)="myFunction()" id="def-val1">
                                        <div class="input-group-append ">
                                            <span class="input-group-text " id="basic-addon2" style="background-color:#1652f0;border:none;">

                                                <button class="btn btn-primary" id="selectidd"
                                                    style="background-color:#1652f0; border:none ;box-shadow: none; "
                                                    (click)="open(content)">{{firstselect}}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="bi bi-caret-down-fill"
                                                        viewBox="0 0 16 16" style="margin-left:15%;">
                                                        <path
                                                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                    </svg>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-5 m-1">
                                    <!--   col   -->
                                    <div class="input-box input-group mb-3 dropdown " id="dropdown2">
                                        <input type="text" class="form-control inputss" value="{{defaultVal2}}" name="s" disabled>
                                        <div class="input-group-append ">
                                            <span class="input-group-text " id="basic-addon2" style="background-color:#1652f0;border:none ;">

                                                <button class="btn btn-primary"
                                                    style="background-color:#1652f0; border:none ;box-shadow: none; "
                                                    (click)="open2(content2)">{{secondselect}}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="bi bi-caret-down-fill"
                                                        viewBox="0 0 16 16" style="margin-left:15%;">
                                                        <path
                                                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                    </svg>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- <div class=""> -->

                            <div class="text-center">
                                <button *ngIf="hideButton==1" type="button" class="btn btn-lg m-2 p-2" routerLink="/view-table" style="background-color: red; color: white; font-weight: 700; border-radius: 0%;">View
                                    Offers</button>
                                <button *ngIf="hideButton==0" type="button" class="btn btn-lg m-2" (click)="bigbExchange()" style="background-color: red; color: white; font-weight: 700; border-radius: 0%;" disabled>
                                    Coming Soon
                                </button>
                                <!-- <button>
                                    <a href="https://api.zaakpay.com/api/paymentTransact/V8?amount=100000&buyerEmail=sandeshaher11@gmail.com&currency=INR&merchantIdentifier=9feae83e1cb942e6af9f90b92968648d&orderId=ZPLive1638601791735&returnUrl=http://localhost:8080/response&checksum=de50bf6450687b73a2575399cc0211a29925999e41ae054f1bd5f8102d22afd5">
                                zaakpay</a></button> -->
                            </div>
                            <!-- <a href="http://localhost:8080/transact" class="col text-center btn btn-primary">Transact API</a> -->

                        </div>
                    </form>

                    <!------------------------------------------>

                </div>
            </div>
        </div>


        <ng-template #content let-modal style="width:10%;height:10%;">

            <div class="modal-body" role="dialog">
                <form>
                    <div class="form-group">

                        <input autocomplete="off" type="text" id="myInput" placeholder="Search for names.." name="name" [(ngModel)]="name" (input)="search()" title="Type in a name">
                        <div class="sandesh11">
                            <ul id="myUL">

                                <li> <a *ngFor="let coins of getCoinData_A;" [value]="coins" (click)="selectclick(coins)">
                                        {{coins}} </a></li>

                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>



        <ng-template #content2 let-modal style="width:10%;height:10%;">

            <div class="modal-body" role="dialog">
                <form>
                    <div class="form-group">

                        <input autocomplete="off" type="text" id="myInput" placeholder="Search for names.." title="Type in a name" name="name" [(ngModel)]="name2" (input)="search2()">
                        <div class="sandesh11">
                            <ul id="myUL">

                                <li> <a *ngFor="let coins of getCoinData_B;" [value]="coins" (click)="selectclick2(coins)">
                                        {{coins}} </a></li>

                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>

        <!-- Graph -->
        <div class="graph graph-padding">
            <div class="container">
                <div class="row margin-balance">
                    <div class="col-lg-3 col-6">

                        <div class="graph-item">
                            <div class="graph-content">
                                <h5>ETH / USDT</h5>
                                <div class="lastprice" style="font-weight: 600; font-size: xx-large;" id="stock-price">
                                    <span class="price-change">{{price}}</span>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="col-lg-3 col-6">

                        <div class="graph-item">
                            <div class="graph-content">
                                <h5>BNB / USDT</h5>
                                <div class="lastprice" id="stock-price1" style="font-weight: 600; font-size: xx-large;">
                                    <span class="price-change"> {{price1}}</span>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="graph-item">
                            <div class="graph-content">
                                <h5>BTC / USDT</h5>
                                <div class="lastprice" id="stock-price2" style="font-weight: 600; font-size: xx-large;">
                                    <span class="price-change">{{price2}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="graph-item">
                            <div class="graph-content">
                                <h5>TRX / USDT</h5>
                                <div class="lastprice" id="stock-price3" style="font-weight: 600; font-size: xx-large;">
                                    <span class="price-change">{{price3}}</span>
                                </div>
                            </div>

                        </div>
                        <!-- </a>  -->
                    </div>
                </div>
            </div>
        </div>
        <!-- graph-block -->

    </div>
    <!-- banner-block -->
    <footer class="footer">

    </footer>
    <script src="./assets/js/jquery-1.12.4.min.js"></script>
    <script src="./assets/js/popper.min.js"></script>
    <script src="./assets/js/bootstrap.min.js"></script>
    <script src="./assets/js/slick.min.js"></script>
    <script src="./assets/js/jquery.peity.min.js"></script>
    <script src="./assets/js/jquery.slimscroll.min.js"></script>
    <script src="./js/custom.js"></script>
</body>

</html>