<div class="user-login-signup-section modal-container">
    <div class="container">
        <div class="user-login-signup-form-wrap">


            <!-- ------------------------------------------ User Update Registration Form ------------------------------------- -->
            <div class="modal-content">
                <h3>User Signup</h3>
                <div class="modal-body">
                    <div class="user-connected-form-block">
                        <form class="user-connected-from user-signup-form" [formGroup]="regi">
                            <div class="form-group">
                                <!-- <div class="col"> -->
                                <input type="text"  required class="form-control" value={{f_name}}  formControlName="f_name" 
                                    placeholder="Enter full name as per your PAN">
                                    <div *ngFor="let e of errMsg.f_name">
                                        <ng-container *ngIf="regi.get('f_name').touched && !regi.get('f_name').valid 
                                                && regi.get('f_name').hasError(e.type)">
                                            <small class="error-message" style="color: red;">{{e.message}}</small>
                                        </ng-container>
                                        </div>
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control" required value={{email}}
                                    id="exampleInputEmail2" placeholder="Email address" disabled>
                                
                            </div>

                            <div class="form-group">
                                <input type="password" class="form-control" required value={{password}}
                                    id="exampleInputPassword2" placeholder="Password" disabled>
                               
                            </div>
                            
                            <button type="submit" class="btn btn-default" (click)='update()'>Update</button>
                        </form>
                        <!-- <p>Already have an account? <a [routerLink]="['/login']"> Sign In</a></p> -->
                    </div><!-- create-account-block -->
                </div>
            </div>

          


        </div><!-- user-login-signup-form-wrap -->
    </div>
</div><!-- user-login-signup-section -->

<!-- <app-app-toast aria-live="polite" aria-atomic="true"></app-app-toast> -->

<script src="./assets/js/jquery-1.12.4.min.js"></script>
<script src="./assets/js/popper.min.js"></script>
<script src="./assets/js/bootstrap.min.js"></script>
<script src="./assets/js/slick.min.js"></script>
<script src="./assets/js/jquery.peity.min.js"></script>
<script src="./assets/js/jquery.slimscroll.min.js"></script>
<script src="./js/custom.js"></script>
