import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ViewOfferTableComponent } from './view-offer-table/view-offer-table.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { HttpClientModule  } from '@angular/common/http';
import { AuthserviceService } from './services/authservice.service';
// import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { Step2Component } from './step2/step2.component';
import { FormatTimePipe, Step3Component } from './step3/step3.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { FaqComponent } from './faq/faq.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AboutComponent } from './about/about.component';
import { Signup2Component } from './signup2/signup2.component'; 
// import { SpinnerCircularModule } from 'spinners-angular/spinner-circular';
import { SpinnersAngularModule } from 'spinners-angular';
import { BigbExchangeStep2Component } from './bigb-exchange-step2/bigb-exchange-step2.component';
import { BigbExchangeStep3Component } from './bigb-exchange-step3/bigb-exchange-step3.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { Pipe, PipeTransform } from "@angular/core"
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    SignupComponent,
    ViewOfferTableComponent,
    HowItWorksComponent,
    Step2Component,
    Step3Component,
    FaqComponent,
    FormatTimePipe,
    AboutComponent,
    Signup2Component,
    BigbExchangeStep2Component,
    BigbExchangeStep3Component
  ],
  imports: [
    NgxQRCodeModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule, SpinnersAngularModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
    // NgbModule
    // ToastModule
  ],
  providers: [
    AuthserviceService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
