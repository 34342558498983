
    <div class="subheader">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="subheader-wrapper">
                        <h3>Frequently Asked Questions</h3>
                        <p>
                            Many desktop publishing packages and web page editors now use <br>
                            Lorem Ipsum as their default model text
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="faq-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-5 col-12">
                    <div class="list-group" id="list-tab" role="tablist">
                        <a class="list-group-item list-group-item-action active" id="list-home-list" data-toggle="list" href="#list-home" role="tab">
                            <h5>General Question</h5>
                            <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam</p>
                        </a>
                        <a class="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" href="#list-profile" role="tab">
                            <h5>Pricing</h5>
                            <p>Nor again is there anyone who loves or pursues or desires to obtain pain of itself.</p>
                        </a>
                        <a class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab">
                            <h5>About Features</h5>
                            <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore</p>
                        </a>
                        <a class="list-group-item list-group-item-action" id="list-settings-list" data-toggle="list" href="#list-settings" role="tab">
                            <h5>Terms &amp; Conditions</h5>
                            <p>Sed ut perspiciatis unde omnis iste natus error voluptatem</p>
                        </a>
                    </div>
                </div>
                <div class="offset-lg-1 col-lg-7 col-md-7">
                    <div class="tab-content style-2" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                            <h4>General</h4>
                            <div class="accordion style-2" id="accordion1Faq">
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading1One">
                                    <h5 class="mb-0">
                                        <button type="button" data-toggle="collapse" data-target="#collapse1One" aria-expanded="true" aria-controls="collapse1One">
                                        Explorer of the truth the master?
                                        </button>
                                    </h5>
                                    </div>
        
                                    <div id="collapse1One" class="collapse show" aria-labelledby="heading1One" data-parent="#accordion1Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading1Two">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse1Two" aria-expanded="false" aria-controls="collapse1Two">
                                        Pursues or desires to obtain pain of itself, because?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse1Two" class="collapse" aria-labelledby="heading1Two" data-parent="#accordion1Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading1Three">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse1Three" aria-expanded="false" aria-controls="collapse1Three">
                                        Sxpound the actual teachings of the great explorer?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse1Three" class="collapse" aria-labelledby="heading1Three" data-parent="#accordion1Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading1Four">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse1Four" aria-expanded="false" aria-controls="collapse1Four">
                                        I will give you a complete?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse1Four" class="collapse" aria-labelledby="heading1Four" data-parent="#accordion1Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading1Five">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse1Five" aria-expanded="false" aria-controls="collapse1Five">
                                        One who avoids a pain that produces no?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse1Five" class="collapse" aria-labelledby="heading1Five" data-parent="#accordion1Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
                            <h4>Pricing</h4>
                            <div class="accordion style-2" id="accordion2Faq">
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading2One">
                                    <h5 class="mb-0">
                                        <button type="button" data-toggle="collapse" data-target="#collapse2One" aria-expanded="true" aria-controls="collapse2One">
                                        Explorer of the truth the master?
                                        </button>
                                    </h5>
                                    </div>
        
                                    <div id="collapse2One" class="collapse show" aria-labelledby="heading2One" data-parent="#accordion2Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading2Two">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse2Two" aria-expanded="false" aria-controls="collapse2Two">
                                        Pursues or desires to obtain pain of itself, because?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse2Two" class="collapse" aria-labelledby="heading2Two" data-parent="#accordion2Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading2Three">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse2Three" aria-expanded="false" aria-controls="collapse2Three">
                                        Sxpound the actual teachings of the great explorer?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse2Three" class="collapse" aria-labelledby="heading2Three" data-parent="#accordion2Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading2Four">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse2Four" aria-expanded="false" aria-controls="collapse2Four">
                                        I will give you a complete?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse2Four" class="collapse" aria-labelledby="heading2Four" data-parent="#accordion2Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading2Five">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse2Five" aria-expanded="false" aria-controls="collapse2Five">
                                        One who avoids a pain that produces no?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse2Five" class="collapse" aria-labelledby="heading2Five" data-parent="#accordion2Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
                            <h4>About Features</h4>
                            <div class="accordion style-2" id="accordion3Faq">
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading3One">
                                    <h5 class="mb-0">
                                        <button type="button" data-toggle="collapse" data-target="#collapse3One" aria-expanded="true" aria-controls="collapse3One">
                                        Explorer of the truth the master?
                                        </button>
                                    </h5>
                                    </div>
        
                                    <div id="collapse3One" class="collapse show" aria-labelledby="heading3One" data-parent="#accordion3Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading3Two">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse3Two" aria-expanded="false" aria-controls="collapse3Two">
                                        Pursues or desires to obtain pain of itself, because?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse3Two" class="collapse" aria-labelledby="heading3Two" data-parent="#accordion3Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading3Three">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse3Three" aria-expanded="false" aria-controls="collapse3Three">
                                        Sxpound the actual teachings of the great explorer?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse3Three" class="collapse" aria-labelledby="heading3Three" data-parent="#accordion3Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading3Four">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse3Four" aria-expanded="false" aria-controls="collapse3Four">
                                        I will give you a complete?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse3Four" class="collapse" aria-labelledby="heading3Four" data-parent="#accordion3Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading3Five">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse3Five" aria-expanded="false" aria-controls="collapse3Five">
                                        One who avoids a pain that produces no?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse3Five" class="collapse" aria-labelledby="heading3Five" data-parent="#accordion3Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">
                            <h4>Terms &amp; Conditions</h4>
                            <div class="accordion style-2" id="accordion4Faq">
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading4One">
                                    <h5 class="mb-0">
                                        <button type="button" data-toggle="collapse" data-target="#collapse4One" aria-expanded="true" aria-controls="collapse4One">
                                        Explorer of the truth the master?
                                        </button>
                                    </h5>
                                    </div>
        
                                    <div id="collapse4One" class="collapse show" aria-labelledby="heading4One" data-parent="#accordion4Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading4Two">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse4Two" aria-expanded="false" aria-controls="collapse4Two">
                                        Pursues or desires to obtain pain of itself, because?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse4Two" class="collapse" aria-labelledby="heading4Two" data-parent="#accordion4Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading4Three">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse4Three" aria-expanded="false" aria-controls="collapse4Three">
                                        Sxpound the actual teachings of the great explorer?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse4Three" class="collapse" aria-labelledby="heading4Three" data-parent="#accordion4Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading4Four">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse4Four" aria-expanded="false" aria-controls="collapse4Four">
                                        I will give you a complete?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse4Four" class="collapse" aria-labelledby="heading4Four" data-parent="#accordion4Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="heading4Five">
                                    <h5 class="mb-0">
                                        <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapse4Five" aria-expanded="false" aria-controls="collapse4Five">
                                        One who avoids a pain that produces no?
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapse4Five" class="collapse" aria-labelledby="heading4Five" data-parent="#accordion4Faq">
                                    <div class="accordion-body">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-lg-4 col-lg-5 offset-md-2 col-md-8">
                    <div class="qs-answer-section">
                        <img src="assets/others/30.png" alt="img" class="img-responsive">
                        <h4>Don’t get Answer?</h4>
                        <span>We have a record of answering everything in 3 hours of less.</span>
                        <div class="btn-group">
                            <button class="btn btn-default active">E-mail Us</button>
                            <button class="btn btn-default">Let’s Chat</button>
                        </div>
                        <p>Or maybe you’d like to call us:  <span>(+7 (966) 552-88-46)</span></p>
                    </div><!-- qs-answer-section -->
                </div>
            </div>
        </div>
        </div>
        
        