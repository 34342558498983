import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// const baseUrl = 'http://localhost:5000';
const baseUrl='https://cotoexapi.cotoex.com';

@Injectable({
  providedIn: 'root'
})
export class MysqlserviceService {

  constructor(private httpClient: HttpClient) { }

  zaakpay(){

  }

  postTransactionData(data) {
    return this.httpClient.post(`${baseUrl}/transaction_details`, data)
  }
  uploadImg(user_id, formData) {
    return this.httpClient.post(`${baseUrl}/upload/${user_id}`, formData)
  }
  uploadPanImg(user_id, formData) {
    return this.httpClient.post(`${baseUrl}/uploadpan/${user_id}`, formData)
  }

  addUser(data) {
    // console.log("in service" + JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/add_user`, data);
  }
  getUsername(user_id) {
    return this.httpClient.get(`${baseUrl}/get_user_name/${user_id}`)
  }

  getUsers() {
    return this.httpClient.get(`${baseUrl}/get_user_master`)
  }

  getUsers2(user_id) {
    return this.httpClient.get(`${baseUrl}/get_user_master2/${user_id}`)
  }

  getTxIdSwapSpace(partner, from, to, address, amount, ipAddress) {
    // console.log("in mysql" + partner, from, to, address, amount)
    return this.httpClient.post(`${baseUrl}/post_swapspace_id`, { partner, from, to, address, amount, ipAddress });
  }

  getExchngeInfoSwapspace(data) {
    // console.log("in mysql" + JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/getTransactionInfoSwapspace`, data);
  }



  sendMail(fname, email) {
    // console.log('in get mail mysql' + fname)
    return this.httpClient.get(`${baseUrl}/get_mail/${fname}/${email}`)
  }

  sendMailTransac(email, Tid, fname) {
    // console.log('in send trans mail mysql ' + email)
    return this.httpClient.get(`${baseUrl}/send_mail/${email}/${Tid}/${fname}`)
  }

  getUserId(email) {
    // console.log("in service" + email)
    return this.httpClient.post(`${baseUrl}/get_userid`, email)
  }

  getUserMail(user_id) {
    return this.httpClient.get(`${baseUrl}/get_usermail/${user_id}`)
  }

  addaadharnumber(data) {
    // console.log("in service dhr numbr" + JSON.stringify(data));
    return this.httpClient.post(`${baseUrl}/addaadhar`, data);
  }

  updatepanstatus(data) {
    // console.log("in service pan numbr" + JSON.stringify(data));
    return this.httpClient.post(`${baseUrl}/updatepancard`, data);
  }

  addpannumber(datas) {
    // console.log("in service" + JSON.stringify(datas));
    return this.httpClient.put(`${baseUrl}/addpan`, datas);
  }
  //--------------------------add KYC details to mysql----------------------------------------

  addKycDetails(data) {
    // console.log("data of add kyc details in service")
    return this.httpClient.post(`${baseUrl}/add_kyc_details`, data);
  }

  // ---------------------------------------------- Adhar Pan KYC Verification -----------------------------------

  reqAdhar(datas) {
    return this.httpClient.post(`${baseUrl}/request_verify_adhar`, datas);
  }

  resAdhar(datas) {
    return this.httpClient.post(`${baseUrl}/response_verify_adhar`, datas);
  }

  reqPAN(datas) {
    // console.log("request pan in service", datas)
    return this.httpClient.post(`${baseUrl}/request_verify_pan`, datas);
  }

  updateUserData(data) {
    // console.log("update data in service file" + JSON.stringify(data))
    return this.httpClient.put(`${baseUrl}/update_user_name`, data);
  }

  get_encrypted_password(email) {
    // console.log("in service get encypted password email : ", email)
    return this.httpClient.get(`${baseUrl}/get_encrypted_password/${email}`);
  }

  //------------------------------for validating user email by sending otp----------------------------
  validateEmail(user_email, user_otp) {
    // console.log("in serviceeeee", user_email, user_otp)
    return this.httpClient.get(`${baseUrl}/validate_user_mail/${user_email}/${user_otp}`)
  }

  // ---------------------------------- adding bigb transaction data ---------------------------------

  addBigbTransactionDetails(data) {
    // console.log("in service transactions" + JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/bigb_transaction_details`, data);
  }

  addBigbInrTransactionDetails(data) {
    // console.log("in service transactions" + JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/bigb_inr_transaction_details`, data);
  }

  updateBigbInrTransactionData(data) {
    // console.log("update data in service file" + JSON.stringify(data))
    return this.httpClient.put(`${baseUrl}/update_bigb_inr_transaction_details`, data);
  }

  getbigbInrStatus(order_id) {
    // console.log("in serviceeeee", order_id)
    return this.httpClient.get(`${baseUrl}/get_inr_bigb_status/${order_id}`)
  }

  // ------------------------------------ update transaction status in Transaction Table -----------------------

  updateSSTransactionData(data) {
    // console.log("update data in service file" + JSON.stringify(data))
    return this.httpClient.put(`${baseUrl}/update_transaction_details`, data);
  }
  // ------------------------------------ update transaction status in bigb_inr_transaction Table -----------------------

  updateBBTETransactionData(data) {
    // console.log("update data in service file" + JSON.stringify(data))
    return this.httpClient.put(`${baseUrl}/update_bbtetransaction_details`, data);
  }

  // --------------------------------------- coinpayments calling -------------------------------------- 

  minmaxCoinpayment(data) {
    // console.log('minmaxCoinpayment in mysql service'+JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/coinpayments_minmax`, data)
  } //not called

  createTransCoinpayment(data) {
    // console.log('createTransCoinpayment in mysql service'+JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/coinpayments_createtransaction`, data)
  }

  transactionCoinpayment(data) {
    // console.log('transaction coinpayment in mysql service'+JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/coinpayments_gettransactioninfo`, data)
  }

  // ----------------------------- Razorpay callings ------------------------------------ 
  createorderId(data) {
    return this.httpClient.post(`${baseUrl}/createorderId`, data);
  }
  paymentVerify(data) {
    // console.log("in service" + JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/paymentverify`, data);
  }


 




  // recognizeImagePan(user_id){
  //   return this.httpClient.get(`${baseUrl}/recognize_img_pan/${user_id}`);
  // }

  // recognizeImageAdhar(user_id){
  //   return this.httpClient.get(`${baseUrl}/recognize_img_adhar/${user_id}`);
  // }

  // deleteImageAdhar(user_id){
  //   return this.httpClient.get(`${baseUrl}/unlink_adhar/${user_id}`);
  // }

  // deleteImagePan(user_id){
  //   return this.httpClient.get(`${baseUrl}/unlink_pan/${user_id}`);
  // }
}