import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import Swal from 'sweetalert2'
import { WindowRefService } from '../services/razorpay_service/window-ref.service';

@Component({
  selector: 'app-bigb-exchange-step2',
  templateUrl: './bigb-exchange-step2.component.html',
  styleUrls: ['./bigb-exchange-step2.component.css']
})
export class BigbExchangeStep2Component implements OnInit {

  transaction_data = [];
  table_id: any;
  drp_1: any;
  drp_2: any = 'BIGB';
  amount: any;
  convertedAmt: any;
  generated_id: any;
  @ViewChild('dest_addr') dest_addrElement: ElementRef;
  @ViewChild('refund_addr') refund_addrElement: ElementRef;
  address: any;
  check: any;
  user_id: any;
  transArr: any;
  toLower1: string;
  toLower2: string;
  ipAddress: any;
  loader: any = 1;
  inr_transaction_data = [];

  usdConversion: any = 0;

  constructor(private winRef: WindowRefService, private formm: FormBuilder, private activatedRoute: ActivatedRoute, private http: HttpClient, private route: Router, private mysql: MysqlserviceService) {
    window.scroll(0, 0);
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        // console.log('th data', data);
        this.ipAddress = data.ip
        // console.log("sandesh ip"+ this.ipAddress)
      })
    const token = localStorage.getItem("SeesionUser")
    // console.log("token" + token)
    this.user_id = token;
    this.activatedRoute.params.subscribe(data => {
      this.drp_1 = String(data.drp_1)
      // this.drp_2 = String(data.drp_2)
      this.amount = String(data.amt)
    });
  }

  recipient2 = this.formm.group({
    address: ['', Validators.required],
    check: ['', Validators.required]
  });

  public errMsg = {
    address: [
      { type: 'required', message: 'Please enter correct address' },
    ],
    check: [
      { type: 'required', message: 'Please accept the Terms of Policy' },
    ],
  }

  ngOnInit() {
    var today = new Date();

    console.log('checking dropdown ' + this.drp_1 + '\ndate'+today.toISOString())
    if (this.drp_1 == 'INR') {
      this.convertedAmt = this.amount
    }
    else {
      this.http.get(`https://api.binance.com/api/v3/avgPrice?symbol=${this.drp_1}USDT`).subscribe(b => {
        this.usdConversion = b.valueOf()
        this.usdConversion = this.usdConversion.price * this.amount
        this.convertedAmt = this.usdConversion * 74.34;

      }, (e) => {

      }, () => {
      // const options = { method: 'GET', headers: { Accept: 'application/json' } };
      //   fetch('https://api.fastforex.io/convert?from=USD&to=INR&amount=1&api_key=5d431750be-eb5943028e-r254da', options)
      //     .then(response => response.json())
      //     .then(response => {
      //       console.log("response" + JSON.stringify(response))
      //       console.log("usdconversion" + this.usdConversion)
      //       this.convertedAmt = this.usdConversion * response.result.INR
      //     })
      //     .catch(err => console.error("saknsads" + err));
      })
    }

  }

  gotoStep3() {
    this.clickedExchanger();
    
  }

  clickedExchanger() {
    this.loader = 0;
    // if (this.drp_1 != 'TRX') {
    if (this.drp_1 == 'INR') {
      this.createRzpayOrder(this.amount);
    }
    else {
      var today = new Date();
      var dataArr = {
        currency1: 'USD', // this.secondselect
        currency2: this.drp_1,
        amount: this.amount
      }

      this.mysql.createTransCoinpayment(dataArr).subscribe((a: any) => {
        console.log('createTransCoinpayment------' + JSON.stringify(a))
        this.generated_id = a.txn_id

        this.transaction_data.push({
          user_id: this.user_id,
          id: a.txn_id,
          to_coin: 'BIGB',
          from_coin: this.drp_1,
          to_amount: a.amount,
          from_amount: this.amount,
          transaction_date: today.toISOString(),
          transaction_status: '0',
          bigb_status: 0
        })

        this.mysql.addBigbTransactionDetails(this.transaction_data).subscribe(res => {
          console.log('bigb transaction posted------' + JSON.stringify(res))
        }, (e) => {
          console.log('bigb transaction posted error------' + JSON.stringify(e))
        })

      }, (e) => {

      }, () => {
        console.log('user mail' + this.user_id)
        this.mysql.getUserMail(this.user_id).subscribe(a => {
          // console.log('response'+JSON.stringify(a))
          this.mysql.sendMailTransac(a[0].email, this.generated_id, a[0].f_name).subscribe(a => {
            this.route.navigateByUrl(`/bigb-step3/${this.generated_id}`)
            // console.log('mail sent success')
          }, e => {
            // console.log("error sending mail"+JSON.stringify(e))
          }, () => {

          })
        })
      })
    }
    // } else {

    // }
  }




  //  ---------------------------------------- RazorPay Implementation ----------------------------------------------------


  createRzpayOrder(data) {
    //  console.log(data);
    var amt = { amount: data }
    this.mysql.createorderId(amt).subscribe((a: any) => {
      console.log("ssssssss" + JSON.stringify(a))
      this.payWithRazor(a.id);
    });
    // call api to create order_id

  }

  payWithRazor(val) {
    var today = new Date();
    const options: any = {
      key: 'rzp_live_o0bZVKt8Nm0Own',
      amount: this.amount + '00', // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'COTOEX', // company name or product name
      description: 'techteam@cotoex.com',  // product description
      image: 'https://cotoex.com/assets/razorpay_cotoex1.png', // company logo or product image
      order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#1c2769'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      console.log("s222" + JSON.stringify(response));
      console.log("s33" + JSON.stringify(options));
      // call your backend api to verify payment signature & capture transaction
      var da = {
        razorpay_order_id: response.razorpay_order_id,
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_signature: response.razorpay_signature
      }
      console.log("data" + JSON.stringify(da))
      this.mysql.paymentVerify(da).subscribe((a: any) => {
        console.log("payment_verify" + JSON.stringify(a))
        if (a.signatureIsValid == "true") {
          alert("Send bigb coins")  // send bigb coins to user 
          this.inr_transaction_data.push({
            user_id: this.user_id,
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            to_amount: this.convertedAmt,
            from_amount: this.amount,
            transaction_date: today.toISOString(),
            transaction_status: 'success'
          })
          this.mysql.addBigbInrTransactionDetails(this.inr_transaction_data).subscribe(a => {
            console.log("data store succesfully inrbigb"+JSON.stringify(a))
          },(e)=>{
            console.log(" error data store inrbigb"+JSON.stringify(e))
          },()=>{
            this.loader = 1;
            this.route.navigateByUrl(`/bigb-step2/${this.drp_1}/${this.amount}`)
          })
        }
        else {
          Swal.fire({
            title: 'Error',
            text: `Transaction failed`,
            icon: 'error',
            confirmButtonText: 'Ok',
            width: '400'
          }).then(a =>{
            var data = { 
              transaction_status: 'failed',
              user_id: this.user_id
            }
            this.mysql.updateBigbInrTransactionData(data).subscribe(res => {
              console.log("data updated succesfully inrbigb"+JSON.stringify(res))
            },(e)=>{
              console.log(" error data updated inrbigb"+JSON.stringify(e))
            },()=>{
              this.loader = 1;
              this.route.navigateByUrl(`/bigb-step2/${this.drp_1}/${this.amount}`)
            })
          })
        }
      });
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
      this.loader = 1;
      Swal.fire({
        title: 'Error',
        text: `Transaction failed`,
        icon: 'error',
        confirmButtonText: 'Ok',
        width: '400'
      }).then(a =>{
        
        this.route.navigateByUrl(`/dashboard`)
      })
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }







}

// var dataArr = {
//   currency1 : 'TRX', // this.secondselect
//   currency2 : this.firstselect,
//   amount : this.usernameElement.nativeElement.value
// }

// this.mysql.createTransCoinpayment(dataArr).subscribe(a => {
//   console.log('createTransCoinpayment------'+JSON.stringify(a))
// },(e)=>{

// },()=>{

// })