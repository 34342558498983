import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ThisReceiver } from '@angular/compiler';
import { ActivatedRoute, Router } from '@angular/router';
import * as redstone from 'redstone-api';
import { WindowRefService } from '../services/razorpay_service/window-ref.service';
import Swal from 'sweetalert2';
import { Observable, Subscription, timer } from 'rxjs';
import { AppToastServiceService } from '../services/app-toast-service.service';
import {HmacSHA256} from 'crypto-js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  tick = 1000;
  counter = 0;
  countDown: any;

  @ViewChild('valueId') usernameElement: ElementRef;
  @ViewChild('selectid') optselElement1: ElementRef;

  selectForm: FormGroup;

  ws = new WebSocket('wss://stream.binance.com:9443/ws/ethusdt@kline_5m');
  ws1 = new WebSocket('wss://stream.binance.com:9443/ws/bnbusdt@kline_5m');
  ws2 = new WebSocket('wss://stream.binance.com:9443/ws/btcusdt@kline_5m');
  ws3 = new WebSocket('wss://stream.binance.com:9443/ws/trxusdt@kline_5m');

  stockPriceElement = document.getElementById('stock-price');
  stockPriceElement1 = document.getElementById('stock-price1');
  stockPriceElement2 = document.getElementById('stock-price2');
  stockPriceElement3 = document.getElementById('stock-price3');

  sign = '$';
  mySelect: any;
  lastPrice = null;
  stockObject;
  price: any;
  firstselect: any;
  secondselect: any;

  lastPrice1 = null;
  stockObject1;
  price1: any;

  lastPrice2 = null;
  stockObject2;
  price2: any;

  lastPrice3 = null;
  stockObject3;
  price3: any;

  defaultVal = 1;
  defaultVal2;
  getCoinData_A: any = [];
  getCoinData_B: any = [];
  selectedValue: any;
  toLower1: string;
  toLower2: string;
  binanceVal: any;
  binanceVal1: any;
  closeResult: any = '';
  mlist: any = [];
  mlistB: any = [];
  name: string;
  name2: string;
  hideButton: any = 1;

  bigb_bnb: any;

  usdConversion: any;
  x: number;

  day:any = 0;
  hr:any = 0;
  min:any = 0;
  sec:any = 0;

  input1Css: any;
  input2Css: any;
  constructor(private route: Router, private mysql: MysqlserviceService, private http: HttpClient,
    private toastService: AppToastServiceService,private activatedRoute: ActivatedRoute,

    private fb: FormBuilder, private modalService: NgbModal, private winRef: WindowRefService) {
      
      // if(route.isActive == true)
      this.timer();
  }
  ngOnDestroy() {
    clearInterval(this.x);
    
  }
  ngAfterViewInit() {
    
    // throw new Error('Method not implemented.');
    //--------------------get all currencies----------------
    this.http.get(`https://api.swapspace.co/api/currencies`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((a: any) => {
      a.forEach(b => {

        this.getCoinData_A.push(b.code)
        this.getCoinData_B.push(b.code)

      })

    }, (e) => {

    }, () => {
      this.getCoinData_B.push('bigb')
      this.getCoinData_A.forEach(a => {
        this.mlist.push(a)
      })
      this.getCoinData_B.forEach(a => {
        this.mlistB.push(a)
      })
    })

    this.stockPriceElement = document.getElementById('stock-price')
    this.stockPriceElement1 = document.getElementById('stock-price1')
    this.stockPriceElement2 = document.getElementById('stock-price2');
    this.stockPriceElement3 = document.getElementById('stock-price3');

    this.ws.onmessage = (event) => {
      this.stockObject = JSON.parse(event.data);
      this.price = parseFloat(this.stockObject.k.c);
      this.stockPriceElement.innerHTML = parseFloat(this.stockObject.k.c).toFixed(2);
      this.stockPriceElement.style.color = !this.lastPrice || this.lastPrice === this.price ? 'white' : this.price > this.lastPrice ? 'green' : 'red';
      this.lastPrice = this.price;

      this.ws1.onmessage = (event) => {
        this.stockObject1 = JSON.parse(event.data);
        this.price1 = parseFloat(this.stockObject1.k.c);
        this.stockPriceElement1.innerText = parseFloat(this.stockObject1.k.c).toFixed(2);
        this.stockPriceElement1.style.color = !this.lastPrice1 || this.lastPrice1 === this.price1 ? 'white' : this.price1 > this.lastPrice1 ? 'green' : 'red';
        this.lastPrice1 = this.price1;

        this.ws3.onmessage = (event) => {
          this.stockObject3 = JSON.parse(event.data);
          this.price3 = parseFloat(this.stockObject3.k.c);
          this.stockPriceElement3.innerText = parseFloat(this.stockObject3.k.c).toFixed(4);
          this.stockPriceElement3.style.color = !this.lastPrice3 || this.lastPrice3 === this.price3 ? 'white' : this.price3 > this.lastPrice3 ? 'green' : 'red';
          this.lastPrice3 = this.price3;
        }

        this.ws2.onmessage = (event) => {
          this.stockObject2 = JSON.parse(event.data);
          this.price2 = parseFloat(this.stockObject2.k.c);
          this.stockPriceElement2.innerText = parseFloat(this.stockObject2.k.c).toFixed(2);
          this.stockPriceElement2.style.color = !this.lastPrice2 || this.lastPrice2 === this.price2 ? 'white' : this.price2 > this.lastPrice2 ? 'green' : 'red';
          this.lastPrice2 = this.price2;

          if (this.secondselect != 'BIGB') {
            this.binanceExchanger();
          }
        }
      }
    }
  }


  open(content) {
    // console.log("content" + this.firstselect)

    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
      });
  }


  open2(content) {
    // console.log("content2" + this.secondselect)
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  ngOnInit() {

    // var checksum = HmacSHA256('amount=1000000&buyerEmail=sandeshaher11@gmail.com&currency=INR&merchantIdentifier=9feae83e1cb942e6af9f90b92968648d&orderId=ZPLive1638605344392&returnUrl=https://cotoex.com/dashboard&','9fe4773f9018477780630fce2105917a');
    // console.log('checksum '+checksum)

    this.toastService.showInfo("Testing Version", "Warning");

    // this.timer();
    this.firstselect = 'BTC';
    this.secondselect = 'ETH';

    this.selectForm = this.fb.group({
      selectControl1: ['BTC'],
      selectControl2: ['ETH']
    });

  }

  timer() {
    var countDownDate = new Date(`december  25, 2021 12:00:00 PDT`).getTime();

    this.x = setInterval(function () {
      var now = new Date().getTime();
      // console.log('now' + now)
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // console.log('distance'+distance)

      // Time calculations for days, hours, minutes and seconds
      this.day = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hr = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.sec = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      // console.log("nbsfdshf"+document.getElementById("demo2").isConnected)
      if((document.getElementById("demo2").isConnected) == true){
      document.getElementById("demo2").innerHTML = "( " + this.day + "d " + this.hr + "h "
        + this.min + "m " + this.sec + "s " + ")";
      }

      // If the count down is over, write some text 
      // if (dE < 0) {
      //   clearInterval(this.x);
      //   document.getElementById("demo").innerHTML = "EXPIRED";
      // }
    });
  }




  search(){// for first search box
    this.getCoinData_A = []
    //push mlist(all coins data) to getCoinData_A array
    this.mlist.forEach(a => {
      this.getCoinData_A.push(a)
      return a
    })
    if (this.secondselect == 'BIGB') {
      this.firstselect = 'INR'
      this.getCoinData_A = []
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      this.hideButton == 1;
    }
    if (this.secondselect == "BIGB") {
      if (this.firstselect == 'inr' || this.firstselect == 'bnb' || this.firstselect == 'btc' || this.firstselect == 'trx' || this.firstselect == 'eth') {
        this.getCoinData_A = []
        this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      }
      this.getCoinData_A = []
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      this.bigb_Conversion(this.firstselect);
    }
    if (this.name.toLocaleLowerCase() == '' && this.secondselect == 'BIGB') {
      this.getCoinData_A = []
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
    }else if (this.name.toLocaleLowerCase() == ''){   //if we not type anything in search box then also all coin list will show
      this.mlist.forEach(a => {
        this.getCoinData_A.push(a)
        return a
      })
    }
    //match typed coin in search list
    this.getCoinData_A = this.getCoinData_A.filter((res: any) => {
      return res.match(this.name.toLocaleLowerCase())
    });
  }


  zaakpayCheck(){
    // this.http.get('http://localhost:5000/zaakpay').subscribe(a => {
    //   console.log('zaakpay')
    // },e => {
      // console.log('zaakpay')
    // })
    // this.mysql.zaakpay()
    // var checksum = HmacSHA256('amount=100000&buyerEmail=sandeshaher11@gmail.com&currency=INR&merchantIdentifier=9feae83e1cb942e6af9f90b92968648d&orderId=ZPLive1638601791735&returnUrl=http://localhost:8080/response&','60ac2fd7c3d14c45bfbc87192bd13ddc');
    // console.log('checksum '+checksum)
  }
  // checksum=de50bf6450687b73a2575399cc0211a29925999e41ae054f1bd5f8102d22afd5

  search2() {

    this.getCoinData_B = []

    //mlistB => all coin data + bigb coin
    // and we push mlistB in getCoinData_B array
    this.mlistB.forEach(a => {
      this.getCoinData_B.push(a)
      return a
    })

    // if second select is BIGB then first select options are only 'inr', 'bnb', 'btc', 'trx', 'eth'
    if (this.secondselect == 'BIGB') {
      // console.log("hi same secondselect")

      this.firstselect = 'INR'
      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')

      this.hideButton == 1;
    }


    if (this.name2.toLocaleLowerCase() == '')//if we not type anything in search box then also all coin list will show
    {
      this.mlistB.forEach(a => {
        this.getCoinData_B.push(a)
        return a
      })
    }

    //match typed coin in search list
    this.getCoinData_B = this.getCoinData_B.filter((res: any) => {
      return res.match(this.name2.toLocaleLowerCase())
    }
    );
  }


  //for selcting the coin in search modal box
  selectclick(data1: any) {
    // console.log("selectclick" + data1)

    // console.log("-----------",this.getCoinData_A)
    // console.log("mlist---------",this.mlist)

    this.mlist.forEach(a => {
      this.getCoinData_A.push(a)
    })

    this.firstselect = data1.toUpperCase();
    this.modalService.dismissAll()
    this.name = '';
    if (this.secondselect == "BIGB") {
      if (data1 == 'inr' || data1 == 'bnb' || data1 == 'btc' || data1 == 'trx' || data1 == 'eth') {
        this.getCoinData_A = []
        // this.getCoinData_A.push('inr')
        this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')

      }
      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')

      this.bigb_Conversion(this.firstselect);

    }

    if (this.firstselect == this.secondselect) {

      if (this.firstselect != 'BTC') {
        this.secondselect = 'BTC'
        this.binanceExchanger();
      }
      else {
        this.secondselect = 'ETH'
        this.binanceExchanger();
      }
    }
  }

  selectclick2(data1: any) {
    // console.log("selectclick2" + data1)
    this.mlistB.forEach(a => {
      this.getCoinData_B.push(a)
    })
    this.secondselect = data1.toUpperCase();
    this.modalService.dismissAll()
    this.name2 = ''
    if (data1 == 'bigb') {

      this.firstselect = 'INR'
      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')

      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      this.hideButton = 0;
      this.bigb_Conversion(data1);
    }
    else {
      this.hideButton = 1;
      if (this.firstselect == 'INR') {
        this.firstselect = 'ETH';
      }
      this.getCoinData_A = []
      this.getCoinData_B = []

      //api for get all currencies
      this.http.get(`https://api.swapspace.co/api/currencies`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
        }
      }).subscribe((a: any) => {
        // console.log("a : ",a)
        a.forEach(b => {
          this.getCoinData_A.push(b.code)
          this.getCoinData_B.push(b.code)
        })

      }, (e) => {

      }, () => {
        this.getCoinData_A.forEach(a => {
          this.mlist.push(a)
        })
        this.getCoinData_B.forEach(a => {
          this.mlistB.push(a)
        })

      })
      if (this.firstselect == this.secondselect) {
        if (this.firstselect != 'ETH') {
          this.secondselect = 'ETH'
          this.binanceExchanger();
        }
        else {
          this.secondselect = 'BTC'
          this.binanceExchanger();
        }
      }
    }
  }



  myFunction() {
    // console.log('my functionthis.usernameElement.nativeElement.value ' + this.usernameElement.nativeElement.value)


    if (this.secondselect != 'BIGB') {
      this.binanceExchanger()
    }
    else {
      this.bigb_Conversion(this.firstselect);
    }
  }

  binanceExchanger() {
    // this.toLower1 = String(this.selectForm.value.selectControl1).toLowerCase()
    // this.toLower2 = String(this.selectForm.value.selectControl2).toLowerCase()

    this.binanceVal = null;

    //for get value of coin in $
    this.http.get(`https://api.binance.com/api/v3/avgPrice?symbol=${this.firstselect}USDT`).subscribe(b => {
      this.binanceVal = b.valueOf()
      this.binanceVal = this.binanceVal.price

      this.http.get(`https://api.binance.com/api/v3/avgPrice?symbol=${this.secondselect}USDT`).subscribe(b => {
        this.binanceVal1 = b.valueOf()
        this.binanceVal1 = this.binanceVal1.price
      }, () => {

      }, () => {
        // get amont of first coin  => second coin price
        var coinVal = this.binanceVal * this.usernameElement.nativeElement.value;
        var dd = coinVal / this.binanceVal1;
        this.defaultVal2 = dd.toFixed(6);
      })
    })
  }

  bigbExchange() {
    // this.coinservice.basicInfoCall()
    var user_mail = localStorage.getItem("email");
    // console.log('from: ' + this.firstselect + ' to: ' + this.secondselect + 'amount: ' + this.usernameElement.nativeElement.value)

    if (this.firstselect == 'INR') {
      // this.createRzpayOrder(this.usernameElement.nativeElement.value);
      // this.route.navigateByUrl(`/bigb-step2/${this.firstselect}/${this.usernameElement.nativeElement.value}`);


    }
    else {
      // this.route.navigateByUrl(`/bigb-step2/${this.firstselect}/${this.usernameElement.nativeElement.value}`);
    }
  }

  bigb_Conversion(data) {
    this.toLower1 = String(data).toUpperCase()
    this.defaultVal2 = 'Not Available';
    // console.log('hiii' + data + 'firstselect'+this.firstselect)
    // console.log('this.usernameElement.nativeElement.value ' + this.usernameElement.nativeElement.value)
    // if (this.firstselect == 'INR') {
    //   // console.log('if INR' + data)
    //   this.http.get(`https://indiapi.bighbull.com/api/getbigrate`).subscribe((a:any) => {
    //     var bigbPrice = a * 75
    //      if(this.usernameElement.nativeElement.value < bigbPrice){
    //       // this.defaultVal2=`min: ${a * 75}`;
    //       this.defaultVal2 = 'Not Available';

    //     }else{
    //       this.defaultVal2 = 'Not Available';
    //       // this.defaultVal2 = this.usernameElement.nativeElement.value / bigbPrice
    //     }

    //     // console.log('done' + a * 75)
    //     // var bigbPrice = a * 75
    //     // this.defaultVal2 = bigbPrice * this.usernameElement.nativeElement.value;

    //   })
    // }
    // else {

    //   //-------get first select's one coin's value in $
    //     const r = redstone.getPrice(this.firstselect).then((price) =>{
    //       this.usdConversion = price.value;

    //       // console.log('\n usdconversion'+this.usdConversion);

    //     // this.usdConversion = price in $ * amount of coin(selected in first search)
    //     this.usdConversion = this.usdConversion * this.usernameElement.nativeElement.value
    //     this.defaultVal2 = this.usdConversion * 74.14

    //     const options = { method: 'GET', headers: { Accept: 'application/json' } };

    //     // fetch('https://api.fastforex.io/convert?from=USD&to=INR&amount=1&api_key=5d431750be-eb5943028e-r254da', options)
    //     //   .then(response => response.json())
    //       // .then(response => {       //currently this api is not working as it's api key is invalid
    //     //     console.log("response" + JSON.stringify(response))
    //     //     console.log("usdconversion" + this.usdConversion)
    //     //     console.log("response.result.INR : ",response.result.INR)
    //     //     //convert value in INR
    //     //     this.defaultVal2 = this.usdConversion * response.result.INR
    //     //   })
    //     //   .catch(err => console.error("fetch" + err));
    //   }).catch(err => console.error("getPrice" + err));
    // }
  }




  //  ---------------------------------------- RazorPay Implementation ----------------------------------------------------


  createRzpayOrder(data) {
    //  console.log(data);
    var amt = { amount: data }
    this.mysql.createorderId(amt).subscribe((a: any) => {
      // console.log("ssssssss"+JSON.stringify(a))
      this.payWithRazor(a.id);
    });
    // call api to create order_id

  }

  payWithRazor(val) {
    const options: any = {
      key: 'rzp_live_o0bZVKt8Nm0Own',
      amount: 500, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'BIGH BULL', // company name or product name
      description: 'support@bighbull.com',  // product description
      image: 'https://cotoex.com/assets/razorpay_cotoex1.png', // company logo or product image
      order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#1c2769'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      // console.log("s222"+JSON.stringify(response));
      // console.log("s33"+JSON.stringify(options));
      // call your backend api to verify payment signature & capture transaction
      var da = {
        razorpay_order_id: response.razorpay_order_id,
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_signature: response.razorpay_signature
      }
      //  console.log("data"+JSON.stringify(da))
      this.mysql.paymentVerify(da).subscribe((a: any) => {
        // console.log("ss44"+JSON.stringify(a))
        if (a.signatureIsValid == "true") {
          // alert("SEnd bigb coins")
        }
        else {
          Swal.fire({
            title: 'Error',
            text: `Transaction failed`,
            icon: 'error',
            confirmButtonText: 'Ok',
            width: '400'
          })
        }

      });
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      // console.log('Transaction cancelled.');
      Swal.fire({
        title: 'Error',
        text: `Transaction failed`,
        icon: 'error',
        confirmButtonText: 'Ok',
        width: '400'
      })
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }



}


// ------------------------------- minmax coinpayment-----------------------------------------
      //  function for dropdown click
      // var datArr = {
      //   from: data,
      //   to: this.firstselect
      // }
      // console.log("data array-----"+JSON.stringify(datArr)+'\ndata'+data)

      // this.mysql.minmaxCoinpayment(datArr).subscribe(a => {
      //   // response
      //   // {"rate":0,"min":"0.00337500","max":"0.00000000","shapeshift_linked":true}

      //   console.log("minm accoinoayment data-----"+JSON.stringify(a))
      // })



// ------------------------------------ create transaction coinpayments response --------------------------------------
// {"amount":"6027.37599000","txn_id":"CPFK6JQMKJAQU9WIKHJXYS5N4X","address":"TS2b5MbaBUns7Gm8f9NS5mBemCPiTq2dLd",
// "confirms_needed":"10","timeout":7200,
// "checkout_url":"https://www.coinpayments.net/index.php?cmd=checkout&id=CPFK6JQMKJAQU9WIKHJXYS5N4X&key=e8a56659755ce3b11720fef141d932b6",
// "status_url":"https://www.coinpayments.net/index.php?cmd=status&id=CPFK6JQMKJAQU9WIKHJXYS5N4X&key=e8a56659755ce3b11720fef141d932b6",
// "qrcode_url":"https://www.coinpayments.net/qrgen.php?id=CPFK6JQMKJAQU9WIKHJXYS5N4X&key=e8a56659755ce3b11720fef141d932b6"
// } 