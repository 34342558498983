<!DOCTYPE html>
<html lang="en">

<head>
    <title>Excoin cryptowatch</title>
</head>

<body>
    <div class="banner-block">
        <div class="container">
            <div class="row">
                <div class="col" id="content1">
                    <h4 style="color: white;--float: left;">1. Enter the amount and choose the swap service</h4>
                    <br>
                    <h2 class="headerstyle" style="color: white; padding-top: 2%;padding-bottom: 2%;"><strong>2. Enter
                            the
                            recipient address</strong></h2>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <form [formGroup]="recipient2">
                        <div class="form-row">
                            <div class="col-6">
                                <h5>You want to exchange:</h5>
                            </div>
                            <div class="col-6">
                                <h5>You will get:</h5>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <h4 style="font-size:x-large;"><strong>{{amount}} {{drp_1}}</strong> </h4>
                            </div>
                            <div class="col-6">
                                <h4 style="font-size:x-large;"><strong>{{convertedAmt}} {{drp_2}}</strong> </h4>
                            </div>
                        </div>
                        <div class="form-row" style="padding-top: 2%;">
                            <label for="exampleFormControlTextarea1" class="asterisk">Please, provide the {{drp_2}}
                                address :
                            </label>
                            <a onclick="window.open('../../assets/BIGB_TrustWallet.pdf')" download="BIGB_TrustWallet"
                                class="nav-link"
                                style="cursor: pointer; margin-left: 40%; color: #ff1e00;text-decoration: underline;">
                                How to generate BIGB address?
                            </a>
                            <textarea class="form-control" placeholder="Enter {{drp_2}} address"
                                id="exampleFormControlTextarea1" rows="3" formControlName="address" required
                                style="background-color:rgba(0,0,0,0);color:white" #dest_addr></textarea>
                            <div *ngFor="let e of errMsg.address">
                                <ng-container *ngIf="recipient2.get('address').touched && !recipient2.get('address').valid 
                                        && recipient2.get('address').hasError(e.type)">
                                    <small class="error-message" style="color: red;">{{e.message}}</small>
                                </ng-container>
                            </div>

                        </div>



                        <!-- <div class="form-row" style="padding-top: 2%;">
                            <label for="exampleFormControlTextarea1">Please, provide the {{drp_1}} refund address (<span style="color: red;">optional</span>):
                            </label>
                            <textarea class="form-control" placeholder="Enter the refund address"
                                id="exampleFormControlTextarea1" rows="3"
                                style="background-color:rgba(0,0,0,0);color:white" #refund_addr></textarea>
                        </div> -->
                        <div class="form-row" style="padding-top: 1%;">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="gridCheck" formControlName="check">
                                <label class="form-check-label asterisk" for="gridCheck">
                                    I accept the Terms of Use and Privacy Policy
                                </label>
                                <div *ngFor="let e of errMsg.check">
                                    <ng-container *ngIf="recipient2.get('check').touched && !recipient2.get('check').valid 
                                        && recipient2.get('check').hasError(e.type)">
                                        <small class="error-message" style="color: red;">{{e.message}}</small>
                                    </ng-container>
                                </div>
                            </div>
                        </div>


                        <div class="text-center" *ngIf="loader==1">
                            <button type="submit" class="btn btn-danger btn-lg" (click)="gotoStep3()"
                                [disabled]="!recipient2.valid">NEXT</button>

                        </div>

                        <div class="text-center" *ngIf="loader==0">
                            <button class="btn btn-danger btn-lg buttonload">
                                <i class="fa fa-spinner fa-spin"></i> Loading
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col" id="content1">
                    <h4 style="padding-top: 2%;--float:left"><strong>3. Transfer your funds to the exchange
                            service</strong></h4>
                </div>
            </div>
            <div class="row">
                <div class="col" id="content1">
                    <br>
                    <h4 style="--float:left"><strong>4. Wait for the exchange to proceed</strong></h4>
                </div>
            </div>
        </div>
    </div>


    <!-- Call to Action End -->

    <script src="./assets/js/jquery-1.12.4.min.js"></script>
    <script src="./assets/js/popper.min.js"></script>
    <script src="./assets/js/bootstrap.min.js"></script>
    <script src="./assets/js/slick.min.js"></script>
    <script src="./assets/js/jquery.peity.min.js"></script>
    <script src="./assets/js/jquery.slimscroll.min.js"></script>
    <script src="./js/custom.js"></script>
</body>

</html>