<div class="subheader">
        
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="subheader-wrapper">
                    <h3>Get Started in 3 Steps</h3>

                </div>
            </div>
        </div>
    </div>
</div><!-- sub-header -->


<div class="working-process-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 col-md-6">
                <div class="working-process">
                    <ul class="working-process-list">
                        <li>
                            <div class="working-process-step">
                                <h4><span>01.</span>  Choose the crypto exchange pair</h4>
                                <div class="process-details">
                                    <p>
                                        Here are 3 easy steps of exchanging cryptocurrency with COTOEX. To get started with you need to register yourself
                                        first then get logged in & hence you will be able to start exchanging. Let's say you want to
                                        buy Ethereum for Bitcoin. Select the BTC (You want) in first section & ETH (You get) in second section & then enter
                                        the amount you want to exchange. Now you will be able to see the exchangers & you can select your
                                        favourite one & go with it.
                                    </p>
                            </div>
                        </div><br>
                       
                                 <div class="col-lg-6">
                                    <img src="assets/Screenshot(66).png" class="imgs" height="100%" width="250%">
                                </div>
                      
                        </li>
                        <li>
                            <div class="working-process-step">
                                <h4><span>02.</span>  Enter the recipient’s address</h4>
                                <div class="process-details">
                                    <p>
                                        Now you need to enter the recipient’s ETH (You get) address. After selecting exchanger click on "Exchange"
                                        button and now enter your ETH address to proceed. Be extremely careful and double-check your BIGH address.
                                        You need to accept the terms of use to move ahead & click on 'Next'. Your ETH (You get) coins will be sent to this address right after the exchange.
                                    </p>
                                </div><br>
                                <div class="col-lg-6">
                                    <img src="assets/Screenshot(69).png" class="imgs" height="100%" width="250%">
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="working-process-step">
                                <h4><span>03.</span>  Transfer your funds</h4>
                                <div class="process-details">
                                    <p>
                                        On the exchange page, you will see the address to send the indicated amount of
                                         Bitcoins to continue the exchange. You can copy that address and save it. You will be
                                         able to see the time left to deposit the money. Also you can track your exchange.
                                    </p>
                                </div><br>
                                <div class="col-lg-6">
                                    <img src="assets/Screenshot(68).png" class="imgs" height="100%" width="250%">
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- working-process-section -->

<!-- Video Tutorial -->

    <div class="working-process-section">
        <div class="container">
            
            <div class="section-title-three">
                <h3>Video Reference</h3>
            </div>
            
            <ul class="download-options-list">
                <li>
                    <div class="working-process-step">
                    <div class="process-details">
                        <p>
                            Here is the other way through which you can get to know more about us. In this video you will 
                            be able to see how faster & easier our platform is to use.
                        
                            We will provide real world traders to trade in low transaction fees and swift liquidity. You don't need to manually
                            copy trades, use BIGH Bull Copy Trading Solution and  mirror your trades instantly across unlimited accounts in using
                            different brokers.
                            Our Artificial Intelligence enabled BOT Trading solutions will seeks out effective market indicators to enable smart
                            allocation of funds while putting you in control of your trading machine.
                        </p>
                </div>
                </div>
                </li>
                <li>
                    <div class="col-lg-6">
                        <iframe src="https://www.youtube.com/embed/xH2uk0cgqYc" height="200"></iframe>  
                    </div>
                    
                </li>
                <li>
                    <div class="col-lg-6">
                        <iframe src="https://www.youtube.com/embed/jqtEqnxoKoU" height="200"></iframe>  
                    </div>
                   
                </li>
            </ul>
        
        </div>
    </div>

<!-- colto-section -->
<!-- Call to Action End -->

<script src="./assets/js/jquery-1.12.4.min.js"></script>
<script src="./assets/js/popper.min.js"></script>
<script src="./assets/js/bootstrap.min.js"></script>
<script src="./assets/js/slick.min.js"></script>
<script src="./assets/js/jquery.peity.min.js"></script>
<script src="./assets/js/jquery.slimscroll.min.js"></script>
<script src="./js/custom.js"></script>