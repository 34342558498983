<!DOCTYPE html>
<html lang="en">

<body>
   


    <div class="user-login-signup-section modal-container">

        <div class="container">


            <div class="user-login-signup-form-wrap">
                <div class="modal-content">
                    <h3>User Login</h3>
                    <div class="modal-body">
                        <div class="modal-info-block">
                            <p>Always ensure you're on the correct website</p>
                            <div class="block-inner">
                                <p>
                                    <span>
                                        <i class="fas fa-lock"></i>
                                        https://
                                    </span>
                                    www.cotoex.com
                                </p>
                            </div>
                        </div>
                        <div class="user-connected-form-block">
                            <form class="user-connected-from user-login-form" [formGroup]="regiLogin">
                                <div class="form-group">
                                    <input type="email" formControlName="email" class="form-control" required
                                        id="exampleInputEmail1" placeholder="Email address">

                                    <div *ngFor="let e of errMsg.email">
                                        <ng-container *ngIf="regiLogin.get('email').touched && !regiLogin.get('email').valid 
                                        && regiLogin.get('email').hasError(e.type)">
                                            <small class="error-message" style="color: red;">{{e.message}}</small>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="password" formControlName="password" class="form-control" required
                                         placeholder="Password">

                                    <div *ngFor="let e of errMsg.password">
                                        <ng-container *ngIf="regiLogin.get('password').touched && !regiLogin.get('password').valid 
                                        && regiLogin.get('password').hasError(e.type)">
                                            <small class="error-message" style="color: red;">{{e.message}}</small>
                                        </ng-container>
                                    </div>
                                </div>

                                <button type="submit" (click)="login()" class="btn btn-default"  [disabled]="regiLogin.invalid">Login</button>
                            </form>
                            <p>Don't have an account? <a href="/signup"> Register</a></p>
                        </div><!-- create-account-block -->
                    </div>
                </div>

            </div><!-- user-login-signup-form-wrap -->

        </div>


    </div>

    <!-- <app-app-toast aria-live="polite" aria-atomic="true"></app-app-toast> -->


    <script src="./assets/js/jquery-1.12.4.min.js"></script>
    <script src="./assets/js/popper.min.js"></script>
    <script src="./assets/js/bootstrap.min.js"></script>
    <script src="./assets/js/slick.min.js"></script>
    <script src="./assets/js/jquery.peity.min.js"></script>
    <script src="./assets/js/jquery.slimscroll.min.js"></script>
    <script src="./js/custom.js"></script>

</body>

</html>
