import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})

export class Step2Component implements OnInit {
  transaction_data=[];
  table_id: any;
  drp_1: any;
  drp_2: any;
  amount: any;
  exolicDefault: any;
  convertedAmt: any;
  generated_id: any;
  @ViewChild('dest_addr') dest_addrElement: ElementRef;
  @ViewChild('refund_addr') refund_addrElement: ElementRef;
  ChangeNowValue: any;
  address: any;
  check: any;
  user_id: any;
  transArr: any;
  toLower1: string;
  toLower2: string;
  ipAddress : any;
  loader:any=1;

  constructor(private formm: FormBuilder, private activatedRoute: ActivatedRoute, private http: HttpClient, private route: Router, private mysql: MysqlserviceService) {
    window.scroll(0,0);
    this.http.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {
      // console.log('th data', data);
      this.ipAddress = data.ip
      // console.log("sandesh ip"+ this.ipAddress)
    })
    const token = localStorage.getItem("SeesionUser")
    // console.log("token" + token)
    this.user_id = token;
    this.activatedRoute.params.subscribe(data => {
      this.table_id = String(data.table_id)
      this.drp_1 = String(data.drp_1)
      this.drp_2 = String(data.drp_2)
      this.amount = String(data.amt)
    });

  }

  recipient = this.formm.group({
    address: ['', Validators.required],
    check: ['', Validators.required]
  });

  public errMsg = {
    address: [
      { type: 'required', message: 'Please enter correct address' },
    ],
    check: [
      { type: 'required', message: 'Please accept the Terms of Policy' },
    ],
  }

  ngOnInit(): void {
   
    // const token = this.storage.get("SeesionUser")

   
    this.toLower1 = String(this.drp_1).toLowerCase()
    this.toLower2 = String(this.drp_2).toLowerCase()

    this.http.get(`https://api.swapspace.co/api/amounts?from=${this.toLower1}&to=${this.toLower2}&amount=${this.amount}&partner=${this.table_id}&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: '52d609f5e2a7a7d2c93224cabf46221482478c8dabe2803c48002da4e2c1d1b8333a20bb2e751aecf8'
      }
    }).subscribe((fox: any) => {
      // console.log("jsbdbds +++++++++++" + JSON.stringify(fox))
      this.convertedAmt = fox[0].amount
    },(e => {
      Swal.fire({
        title: 'Error',
        text: `Try another Exchanger \n Something went wrong`,
        icon: 'error',
        confirmButtonText: 'Ok',
        width: '400'
      })
     
    }))

    // console.log("table id=" + this.table_id + "\ndrp_1=" + this.drp_1 + "\ndrp_2=" + this.drp_2 + "\namt=" + this.amount)

    // console.log("destination address" + this.dest_addrElement)

  }

  gotoStep3() {
      this.clickedExchanger();
      this.loader = 0;

  }

  clickedExchanger() {
    this.toLower1 = String(this.drp_1).toLowerCase()
    this.toLower2 = String(this.drp_2).toLowerCase()

    this.mysql.getTxIdSwapSpace(this.table_id,this.toLower1,this.toLower2, this.dest_addrElement.nativeElement.value,this.amount,this.ipAddress).subscribe((a:any) => {
      // console.log("response from "+JSON.stringify(a))
      this.generated_id = a.id
     
      this.transaction_data.push({user_id:this.user_id,id:a.id,to_coin:a.to.code,from_coin:a.from.code,to_amount:a.to.amount,from_amount:a.from.amount,transaction_date:a.timestamps.createdAt,transaction_status:a.status})
      // console.log("transaction data------------------------",this.transaction_data)

      this.mysql.postTransactionData(this.transaction_data).subscribe((res:any)=>{
        // alert("transaction data posted")
      })
      // console.log("generated id"+this.generated_id)
    },(e)=>{
      // console.log("error post exchange"+JSON.stringify(e))
      Swal.fire({
        title: 'Error',
        text: `Try another Exchanger \n Something went wrong `,
        icon: 'error',
        confirmButtonText: 'OK',
        width: '400'
      }).then(a => {
        // this.route.navigateByUrl(`/view-table`)
        this.loader = 1;
        this.recipient.reset();
      })
    },()=>{


      this.mysql.getUserMail(this.user_id).subscribe(a => {
        // console.log('response'+JSON.stringify(a))
        this.mysql.sendMailTransac(a[0].email,this.generated_id,a[0].f_name).subscribe(a => {
          this.route.navigateByUrl(`/step3/${this.generated_id}`)
          // console.log('mail sent success')
        },e => {
          // console.log("error sending mail"+JSON.stringify(e))
        },()=>{
          
        })
      })
    })
  }
}
