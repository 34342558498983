import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import { MysqlserviceService } from './services/mysqlService/mysqlservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('id') transIdElement: ElementRef;
  title = 'website';
  inputIdstatus: any;
  inputId = 0;
  waitcss: any
  confirmcss: any;
  successcss: any;
  overduecss: any;
  successcssss: any;
  loginbtn: any = 0;
  logoutbtn: any = 0;

  isConnected = true;
  
  noInternetConnection: boolean;

  timecheck:any;

  constructor(private http: HttpClient,
    private route: Router, private mysql: MysqlserviceService) {
  }

  ngAfterViewInit() {
    this.waitcss = document.getElementById('wait_id1');
    this.confirmcss = document.getElementById('confirm_id1');
    this.successcss = document.getElementById('success_id1');
    this.overduecss = document.getElementById('overdue_id1');
    this.successcssss = document.getElementById('btntrack');
    // throw new Error('Method not implemented.');
  }



  ngOnInit() {

    let SeesionUser = localStorage.getItem("SeesionUser")
    // console.log("In app.ts file", SeesionUser);

    if (SeesionUser == null) {
      this.loginbtn = 0;
      this.logoutbtn = 1;
    }
    else {
      this.loginbtn = 1;
      this.logoutbtn = 0;
    }
  }
  ngAfterViewChecked() {
    // alert('in if=====')
    let SeesionUser = localStorage.getItem("SeesionUser")
    // console.log("In app.ts file",SeesionUser);

    if (SeesionUser == null) {
      this.loginbtn = 0;
    }
    else {
      this.loginbtn = 1;
    }

    if (this.inputId == 1) {
      // alert('in if')
      this.waitcss = document.getElementById('wait_id1');
      this.confirmcss = document.getElementById('confirm_id1');
      this.successcss = document.getElementById('success_id1');
      this.overduecss = document.getElementById('overdue_id1');
      this.successcssss = document.getElementById('btntrack');

      if (this.inputIdstatus == 'waiting') {
        this.waitcss.style.background = 'lawngreen'
        var bbb=new Date().toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
        if( new Date(this.timecheck).getTime()< new Date(bbb).getTime()){
          this.waitcss.style.background = 'red';
          this.confirmcss.style.background = 'red';
          this.successcss.style.background = 'red';
          this.overduecss.style.background = 'red';
        }
      }
      if (this.inputIdstatus == 'confirming') {
        this.waitcss.style.background = 'lawngreen';
        this.confirmcss.style.background = 'lawngreen';
      }
      if (this.inputIdstatus == 'finished') {
        this.waitcss.style.background = 'lawngreen';
        this.confirmcss.style.background = 'lawngreen';
        this.successcss.style.background = 'lawngreen';
      }
      if (this.inputIdstatus == 'failed') {
        this.waitcss.style.background = 'lawngreen';
        this.confirmcss.style.background = 'lawngreen';
        this.successcss.style.background = 'lawngreen';
        this.overduecss.style.background = 'red';
      }
      

    }
    if (this.inputId == 2) {
      // alert('in if')
      this.waitcss = document.getElementById('wait_id1');
      this.successcss = document.getElementById('success_id1');
      this.overduecss = document.getElementById('overdue_id1');
      this.successcssss = document.getElementById('btntrack');

      if (this.inputIdstatus == 0) {
        this.waitcss.style.background = 'lawngreen';
      }
      if (this.inputIdstatus == 1) {
        this.waitcss.style.background = 'lawngreen';
        this.successcss.style.background = 'lawngreen';
      }
      if (this.inputIdstatus == -1) {
        this.waitcss.style.background = 'lawngreen';
        this.successcss.style.background = 'lawngreen';
        this.overduecss.style.background = 'red';
      }
    }

    if (this.inputId == 3) {
      this.successcss = document.getElementById('success_id1');
      this.overduecss = document.getElementById('overdue_id1');
      this.successcssss = document.getElementById('btntrack');

      if (this.inputIdstatus == 'success') {
        this.successcss.style.background = 'lawngreen';
      }
      if (this.inputIdstatus == 'failed') {
        this.successcss.style.background = 'lawngreen';
        this.overduecss.style.background = 'red';
      }
    }
  }

  btnClick() {

  }

  logout() {
    // console.log(localStorage.length)

    if (localStorage.length != 0) {
      localStorage.removeItem('useremail')
      localStorage.removeItem('SeesionUser')
      localStorage.removeItem('email')
      localStorage.removeItem('userid')
      // this.route.navigateByUrl('/login')
    }
    else {
      //  this.route.navigateByUrl('/login')
    }
    // this.route.navigateByUrl('/login')
  }

  submitTransacId() {
    var data = { trans_id: this.transIdElement.nativeElement.value }
    this.mysql.getExchngeInfoSwapspace(data)
      .subscribe((a: any) => {

        this.inputId = 1;

        this.inputIdstatus = a.status
       
         var aaa=new Date(a.timestamps.expiresAt).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
         this.timecheck=aaa
        setInterval(async () => {
          this.inputId = 0
        }, 4000);
      }, (e) => {
        var dataArr = { trans_id: this.transIdElement.nativeElement.value }

        this.mysql.transactionCoinpayment(dataArr).subscribe((a: any) => {
          // console.log('response' + JSON.stringify(a))
          if (a != '') {
            this.inputIdstatus = a.status
            this.inputId = 2;
            setInterval(async () => {
              this.inputId = 0
            }, 4000);
          }

        }, (e) => {

          this.mysql.getbigbInrStatus(this.transIdElement.nativeElement.value).subscribe((b: any) => {
            // console.log('response getbigb_inr_status' + JSON.stringify(b))
            this.inputIdstatus = b[0].transaction_status

          }, (e) => {
            Swal.fire({
              title: 'Error',
              text: `Enter Valid Transaction Id`,
              icon: 'error',
              confirmButtonText: 'Ok',
              width: '400'
            })
          }, () => {
            // complete of getbigbInrStatus
            this.inputId = 3;
            setInterval(async () => {
              this.inputId = 0
            }, 4000);
          })

        }, () => {
          // complete of transactionCoinpayment
          var updateddata1 = {
            transaction_status: this.inputIdstatus,
            trans_id: this.transIdElement.nativeElement.value
          }
          this.mysql.updateBBTETransactionData(updateddata1).subscribe(a => {

          })
        })
      }, () => {
        // complete of getExchngeInfoSwapspace
        var updateddata = {
          transaction_status: this.inputIdstatus,
          trans_id: this.transIdElement.nativeElement.value
        }
        this.mysql.updateSSTransactionData(updateddata).subscribe(a => {

        })
      })
  }

}