<div class="user-login-signup-section modal-container">
    <div class="container">
        <div class="user-login-signup-form-wrap">


            <!-- ------------------------------------------ User Registration Form ------------------------------------- -->
            <div class="modal-content" *ngIf="page==1">
                <h3>User Signup</h3>
                <div class="modal-body">
                    <div class="user-connected-form-block">
                        <form class="user-connected-from user-signup-form" [formGroup]="regi">
                            <div class="form-group">
                                
                                <input type="text" formControlName="f_name" required class="form-control"
                                    placeholder="Enter full name as per your PAN " 
                                    >
                                <div *ngFor="let e of errMsg.f_name">
                                    <ng-container *ngIf="regi.get('f_name').touched && !regi.get('f_name').valid 
                                            && regi.get('f_name').hasError(e.type)">
                                        <small class="error-message" style="color: red;">{{e.message}}</small>
                                    </ng-container>
                                    
                                </div>
                               
                            </div>
                            <div *ngIf="disableEmailBlock==0">
                            <div class="form-group" *ngIf="user_verification==0">
                                <div class="row">
                                    <div class="col-8">
                                        <input type="email" formControlName="email" class="form-control" required
                                            id="exampleInputEmail2" placeholder="Email address">
                                        <div *ngFor="let e of errMsg.email">
                                            <ng-container *ngIf="regi.get('email').touched && !regi.get('email').valid 
                                            && regi.get('email').hasError(e.type)">
                                                <small class="error-message" style="color: red;">{{e.message}}</small>
                                            </ng-container>
                                        </div>
    
                                    </div>
                                    <div class="col-3" style="margin-top: 5px;">
                                        <button class="btn btn-info" style="font-weight: bold;border:1px solid gray;"
                                            data-toggle="modal" data-target="#exampleModal"
                                            (click)="verify_email()">Verify</button>
    
                                    </div>
                                </div>    
                            </div>
                            <!-- for enter otp -->
                            <div class="form-group" *ngIf="user_verification==1">
                                <div class="row">
                                    <div class="col-8">
                                        <input type="text" formControlName="user_otp" class="form-control" required
                                            id="user_otp2" placeholder="Enter OTP">
                                        <div *ngFor="let e of errMsg.user_otp">
                                            <ng-container *ngIf="regi.get('user_otp').touched && !regi.get('user_otp').valid 
                                            && regi.get('user_otp').hasError(e.type)">
                                                <small class="error-message" style="color: red;">{{e.message}}</small>
                                            </ng-container>
                                        </div>
    
                                    </div>
                                    <div class="col-3" style="margin-top: 5px;">
                                        <button class="btn btn-info" style="font-weight: bold;border:1px solid gray;"
                                            data-toggle="modal" data-target="#exampleModal"
                                            (click)="verify_user_otp()">Submit</button>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                            <!-- <div class="form-group">
                                <input type="email" formControlName="email" class="form-control" required
                                    id="exampleInputEmail2" placeholder="Email address">
                                <div *ngFor="let e of errMsg.email">
                                    <ng-container *ngIf="regi.get('email').touched && !regi.get('email').valid 
                                        && regi.get('email').hasError(e.type)">
                                        <small class="error-message" style="color: red;">{{e.message}}</small>
                                    </ng-container>
                                </div>
                            </div> -->

                            <div class="form-group">
                                <input type="password" formControlName="password" class="form-control" required
                                    id="exampleInputPassword2" placeholder="Password">
                                <div *ngFor="let e of errMsg.password">
                                    <ng-container *ngIf="regi.get('password').touched && !regi.get('password').valid 
                                        && regi.get('password').hasError(e.type)">
                                        <small class="error-message" style="color: red;">{{e.message}}</small>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <div class="custom-checkbox-wrap">
                                        <input type="checkbox" formControlName="check" class="custom-control-input"
                                            required id="customCheck2" name="example1">
                                        <label class="custom-control-label" for="customCheck2">I have read and agree to
                                            the Terms of Use</label>
                                        <div *ngFor="let e of errMsg.check">
                                            <ng-container *ngIf="regi.get('check').touched && !regi.get('check').valid 
                                                && regi.get('check').hasError(e.type)">
                                                <small class="error-message" style="color: red;">{{e.message}}</small>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <button type="submit" (click)="submit()" class="btn btn-default"
                                [disabled]="regi.invalid">NEXT</button>
                        </form>
                        <p>Already have an account? <a [routerLink]="['/login']"> Sign In</a></p>
                    </div><!-- create-account-block -->
                </div>
            </div>

            <!--  ------------------------------- Div for Adhaar Verification ----------------------------------- -->
            
            <div class="modal-content" *ngIf="page==2">
                <h3>Aadhar Verification</h3>
                <div class="modal-body">
                    <div class="user-connected-form-block">
                        <form action="/upload" method="post" enctype="multipart/form-data"
                            class="user-connected-from user-signup-form" [formGroup]="regi2" (ngSubmit)="onSubmit1()">
                            <div class="form-group">
                                <div class="row justify-content-center">
                                    <img src="../../assets/img/Aadhaar_Logo.png" width="30%" height="30%"
                                        style="object-fit: contain;align-content: center;">
                                    
                                </div>
                            </div>
                            <div *ngIf="disableBlock==1">
                                <div class="form-group row" *ngIf="Verification==1">
                                    <div class="col-9">
                                        <input type="text" formControlName="adhaarNo" class="form-control" required
                                            id="exampleInputAdhar" placeholder="Adhaar Number*">
                                        <div *ngFor="let e of errMsg.adhaarNo">
                                            <ng-container *ngIf="regi2.get('adhaarNo').touched && !regi2.get('adhaarNo').valid 
                                        && regi2.get('adhaarNo').hasError(e.type)">
                                                <small class="error-message" style="color: red;">{{e.message}}</small>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-3" style="margin-top: 5px;">
                                        <button class="btn btn-info"
                                            style="font-weight: bold;border:1px solid gray; margin-left:0px"
                                            data-toggle="modal" data-target="#exampleModal"
                                            (click)="verify()">Verify</button>
                                    </div>

                                    <div *ngIf="error==3" class="col-9">
                                        <ng-container>
                                            <small class="error-message" style="color: red; ">Invalid Aadhar
                                                Number</small>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="form-group row" *ngIf="Verification==2">
                                    <div class="col-9">
                                        <input type="text" formControlName="otp" class="form-control" required id="otp"
                                            placeholder="Enter Your otp" #aadharotp>
                                        <p id="otpmsg" *ngIf="otpmsg==1"
                                            style="color: rgb(0, 255, 0); font-weight: bold;">OTP sent on your
                                            registered mobile
                                            number</p>
                                        <div *ngFor="let e of errMsg.otp">
                                            <ng-container *ngIf="regi2.get('otp').touched && !regi2.get('otp').valid 
                                        && regi2.get('otp').hasError(e.type)">
                                                <small class="error-message" style="color: red;">{{e.message}}</small>
                                            </ng-container>
                                        </div>
                                    </div>


                                    <div class="col-3" style="margin-top: 5px;">
                                        <button class="btn btn-info" id="subButton"
                                            style="font-weight: bold;border:1px solid gray; margin-left:0px"
                                            data-toggle="modal" data-target="#exampleModal"
                                            (click)="verifyotp()">Submit</button>
                                    </div>

                                    <div class="col-12" id="otpmsg" style="color:green"></div>

                                    <div *ngIf="error==1" class="col-9">
                                        <ng-container>
                                            <small class="error-message" style="color: red; margin-left: 2px;">Invalid
                                                OTP or Aadhar Number</small>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="row form-group">
                                <div>
                                    <label class="col-9">Aadhar Front side</label>
                                </div>
                                <div class="col-8">
                                    <input #userInput formControlName="adhaar_front_pic" style="border: none;" required
                                        type="file" (change)="loadImageFromDevice1($event)" class="fileInput"
                                        accept="image/png, image/jpeg">
                                    <div *ngFor="let e of errMsg.adhaar_front_pic">
                                        <ng-container *ngIf="regi2.get('adhaar_front_pic').touched && !regi2.get('adhaar_front_pic').valid 
                                            && regi2.get('adhaar_front_pic').hasError(e.type)">
                                            <small class="error-message" style="color: red;">{{e.message}}</small>
                                        </ng-container>
                                    </div>
                                </div>

                            </div>
                            <div class="row form-group">
                                <div>
                                    <label class="col-9">Aadhar Back side</label>
                                </div>
                                <div class="col-8">
                                    <input #userInput required formControlName="adhaar_back_pic" style="border: none;"
                                        type="file" (change)="loadImageFromDevice2($event)" class="fileInput"
                                        accept="image/png, image/jpeg">
                                    <div *ngFor="let e of errMsg.adhaar_back_pic">
                                        <ng-container *ngIf="regi2.get('adhaar_back_pic').touched && !regi2.get('adhaar_back_pic').valid 
                                            && regi2.get('adhaar_back_pic').hasError(e.type)">
                                            <small class="error-message" style="color: red;">{{e.message}}</small>
                                        </ng-container>
                                    </div>
                                </div>

                            </div>

                            <button *ngIf="loader==1" type="submit" (click)="submit2()" [disabled]="!regi2.valid"
                                class="btn btn-default">
                                NEXT</button>
                            <button *ngIf="loader==0" type="submit" class="btn btn-default" disabled><i
                                    class="fa fa-spinner fa-spin"></i>
                                Wait while we are processing</button>
                        </form>
                        <p>Already have an account? <a [routerLink]="['/login']"> Sign In</a></p>
                    </div><!-- create-account-block -->
                </div>
            </div>
            <!--      -------------------------------- End of Adhaar Verification ------------------------------- -->


            <!--    --------------------------------  Pan Card Verification Div ----------------------------- -->
            <div class="modal-content" *ngIf="page==3">
                <h3>Pan Card Verification</h3>
                <div class="modal-body">
                    <div class="user-connected-form-block">
                        <form action="/uploadpan" method="post" enctype="multipart/form-data"
                            class="user-connected-from user-signup-form" [formGroup]="regi3">
                            <div class="form-group">
                                <div class="row justify-content-center">
                                    <img src="../../assets/img/Pan-Card_final.png" width="30%" height="30%"
                                        style="object-fit: contain;align-content: center;">
                                    
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" formControlName="pancardNo" class="form-control" required
                                    id="exampleInputAdhar" placeholder="Pan Number">
                                <div *ngFor="let e of errMsg.pancardNo">
                                    <ng-container *ngIf="regi3.get('pancardNo').touched && !regi3.get('pancardNo').valid 
                                        && regi3.get('pancardNo').hasError(e.type)">
                                        <small class="error-message" style="color: red;">{{e.message}}</small>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div>
                                    <label class="col-9">Upload Pan</label>
                                </div>
                                <div class="col-8">
                                    <input #userInput formControlName="pan_pic" style="border: none;" required
                                        type="file" (change)="loadImageFromDevice3($event)" class="fileInput"
                                        accept="image/png, image/jpeg">
                                    <div *ngFor="let e of errMsg.pan_pic">
                                        <ng-container *ngIf="regi3.get('pan_pic').touched && !regi3.get('pan_pic').valid 
                                            && regi3.get('pan_pic').hasError(e.type)">
                                            <small class="error-message" style="color: red;">{{e.message}}</small>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <button *ngIf="loader==1" type="submit" (click)="submit3()" [disabled]="regi3.invalid"
                                class="btn btn-default">Submit</button>
                            <button *ngIf="loader==0" type="submit" class="btn btn-default" disabled> <i
                                    class="fa fa-spinner fa-spin"></i>Wait while we are processing</button>

                        </form>
                        <p>Already have an account? <a [routerLink]="['/login']"> Sign In</a></p>
                    </div><!-- create-account-block -->
                </div>
            </div>
            <!-- -------------------------------------- End of Pan Card Verification-------------------------- -->


        </div><!-- user-login-signup-form-wrap -->
    </div>
</div><!-- user-login-signup-section -->
<!-- <app-app-toast-failure aria-live="polite" aria-atomic="true"></app-app-toast-failure> -->



<script src="./assets/js/jquery-1.12.4.min.js"></script>
<script src="./assets/js/popper.min.js"></script>
<script src="./assets/js/bootstrap.min.js"></script>
<script src="./assets/js/slick.min.js"></script>
<script src="./assets/js/jquery.peity.min.js"></script>
<script src="./assets/js/jquery.slimscroll.min.js"></script>
<script src="./js/custom.js"></script>