import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ViewOfferTableComponent } from './view-offer-table/view-offer-table.component';
import { AuthenticationGuardGuard } from './authentication-guard.guard'; 
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { FaqComponent } from './faq/faq.component';
import { AboutComponent } from './about/about.component';
import { Signup2Component } from './signup2/signup2.component';
import { BigbExchangeStep2Component } from './bigb-exchange-step2/bigb-exchange-step2.component';
import { BigbExchangeStep3Component } from './bigb-exchange-step3/bigb-exchange-step3.component';

const routes: Routes = [
  {
    path:'',
    redirectTo:'dashboard',
    pathMatch:'full'  
  },
  {
    path:'dashboard',
    component:DashboardComponent
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'signup',
    component:SignupComponent
  },
  {
    path:'view-table',
    component:ViewOfferTableComponent
    // ,canActivate:[AuthenticationGuardGuard]
  },
  {
    path:'how-it-works',
    component:HowItWorksComponent  
  },
  {
    path:'step2/:table_id/:drp_1/:drp_2/:amt',
    component:Step2Component
    ,canActivate:[AuthenticationGuardGuard]
  },
  {
    path:'bigb-step2/:drp_1/:amt',
    component:BigbExchangeStep2Component
    ,canActivate:[AuthenticationGuardGuard]
  },
  {
    path:'step3/:dest_addr',
    component:Step3Component
    ,canActivate:[AuthenticationGuardGuard]
  },
  {
    path:'bigb-step3/:dest_addr',
    component:BigbExchangeStep3Component
    ,canActivate:[AuthenticationGuardGuard]
  },
  {
    path:'faq',
    component:FaqComponent  
  },
  {
    path:'about',
    component:AboutComponent
  },
  {
    path:'signup2/:pan',
    component:Signup2Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
